<main #stage 	
	class="{{this._service.android_ios}}" 
	[ngClass]="'abs stage'"
	id="obj-game"
	(window:resize)="onResize()">

	<!-- header -->
	<app-header [_type]="'gameHeader'" [_data]="playerDataLevel"></app-header>


	<div #grid	
		[ngClass]="'abs grid hidden bg-game'" 	
		id="obj-grid" >

			<!-- dots only -->
			<div class="abs" #dots id="dot-area" *ngIf="_service.settings.grids.drawGridDots"></div>

			<!-- players -->
			<div class="abs" id="players-area">
				<!-- moving cells -->
				<ng-container #movingArea></ng-container>
				<!-- cells -->
				<ng-container *ngFor="let player of playerDataPositions; index as inx">
					<app-cell *ngIf="
						player.whois=='player' || 
						player.whois=='ai' || 
						player.whois=='empty'" 
						[_data]="player" 
						[_created]="inx"></app-cell>
					<app-hole *ngIf="player.whois=='hole'" 
						[_data]="player" 
						[_created]="inx"></app-hole>
					<app-flip *ngIf="player.whois=='flip'" 
						[_data]="player" 
						[_created]="inx"></app-flip>
					<app-field *ngIf="player.whois=='field'" 
						[_data]="player" 
						[_created]="inx"></app-field>
				</ng-container>
			</div>
			
	</div>	
</main>

<svg style="display: none;">
  <defs>
    <filter id="goo">
      <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />
		<feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 30 -5" result="goo" />      
      <feBlend in="SourceGraphic" in2="goo" operator="atop" />
    </filter>
  </defs>
</svg>

<svg style="display: none;">
  <defs>
    <filter id="iosgoo">
      <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="iosblur" />
		<feColorMatrix in="iosblur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 30 -5" result="iosgoo" />      
      <feBlend in="SourceGraphic" in2="iosgoo" operator="atop" />
    </filter>
  </defs>
</svg>

<svg>
	<defs>
	    <clipPath id="leftFlip">
	        <rect x="0" y="0" width="50" height="100" />
	    </clipPath>
	    <clipPath id="rightFlip">
	        <rect x="50" y="0" width="50" height="100" />
	    </clipPath>
	</defs>
</svg>

