<!-- toast for small msgs -->
<div #toastPopup id="toast" class="hidden">
	<aside *ngIf="toastType=='previous'" class="toast-box error noselect"> 
		<i class="icon-info alert"></i>
		<span>Finish a previous stage!</span>
	</aside>
	<aside *ngIf="toastType=='notenoughstars'" class="toast-box error noselect"> 
		<i class="icon-star-outline alert"></i>
		<span>Not enough stars!</span>
	</aside>
	<aside *ngIf="toastType=='acquire_success'" class="toast-box noselect"> 
		<i class="icon-heart alert"></i>
		<span>Goodies received! Use it wisely.</span>
	</aside>
	<aside *ngIf="toastType=='stars_success'" class="toast-box noselect"> 
		<i class="icon-star alert"></i>
		<span>Stars recived!</span>
	</aside>
	<aside *ngIf="toastType=='stars_denied'" class="toast-box error noselect"> 
		<i class="icon-info alert"></i>
		<span>Purchase canceled.</span>
	</aside>
	<aside *ngIf="toastType=='use_denied'" class="toast-box error noselect"> 
		<i class="icon-info alert"></i> 
		<span>You don’t have any goodies.<br>Buy them first!</span>
	</aside>
	<aside *ngIf="toastType=='use_already'" class="toast-box error noselect"> 
		<i class="icon-info alert"></i>  
		<span>You have already applied this protein!</span>
	</aside>
	<aside *ngIf="toastType=='use_success'" class="toast-box noselect"> 
		<i class="icon-heart alert"></i>
		<span>Goodie applied!</span>
	</aside>
	<aside *ngIf="toastType=='resetprogress'" class="toast-box noselect"> 
		<i class="icon-info alert"></i>  
		<span>Progress & transaction deleted!</span>
	</aside>
	<aside *ngIf="toastType=='nolevel'" class="toast-box noselect"> 
		<i class="icon-info alert"></i> 
		<span>You don't have access to this level!</span>
	</aside>
</div>


<!-- main popup window -->
<div #modalPopup id="modal-container" class="hidden">

	<aside *ngIf="popupType=='go'" class="popup-go noselect">
		<p>
			<button id="iamready" class="service" (click)="actionModalWindow('start', '')">START</button>
		</p>
	</aside>


	<aside *ngIf="popupType=='ready'" class="popup-content noselect">
		<h2 id="countdown" class="counting noselect">3</h2>
	</aside>

	<aside *ngIf="popupType=='info'" class="popup-box noselect mainbg">
		<h3>Are you ready?</h3>
		<p>
			You have green cells. Try to conquer all gray and red cells.<br>
			When you split a cell, it splits in half.
		</p>
		<p>
		<button (click)="actionModalWindow('close', '.btn-thumb')" class="rounded drop positive">
			<i class="icon-thumbs-up"></i>
		</button>
		</p>
	</aside>

	<aside *ngIf="popupType=='lost'" class="popup-box noselect mainbg">
		<h3>A.I. has defeated you!</h3>
		<p class="noselect stars drop">
			<i class="icon-star-outline cards"></i>
			<i class="icon-star-outline cards"></i>
			<i class="icon-star-outline cards"></i>
		</p>
		<p>
			Sometimes you lose. Nothing you can do but admit it.<br>
		</p>
		<p>
		<button (click)="actionModalWindow('home', '.btn-thumb')" class="rounded drop positive">
			<i class="icon-tick"></i>
		</button>
		</p>
	</aside>

	<aside *ngIf="popupType=='win'" class="popup-box noselect mainbg">
		<h3>You won!</h3>
		<p class="noselect stars drop">
			<span *ngIf="popupObject.stars==1">				
				<i class="icon-star cards"></i>
				<i class="icon-star-outline cards"></i>
				<i class="icon-star-outline cards"></i>
			</span>
			<span *ngIf="popupObject.stars==2">				
				<i class="icon-star cards"></i>
				<i class="icon-star cards"></i>
				<i class="icon-star-outline cards"></i>
			</span>
			<span *ngIf="popupObject.stars==3">
				<i class="icon-star cards"></i>
				<i class="icon-star cards"></i>
				<i class="icon-star cards"></i>
			</span>
		</p>
		<p> Good job! A winner never stops trying.</p>
		<p>
		<button (click)="actionModalWindow('home', '.btn-thumb')" class="rounded drop positive">
			<i class="icon-thumbs-up"></i>
		</button>
		</p>
	</aside> 
 
	<aside *ngIf="popupType=='best'" class="popup-box noselect mainbg">
		<h3>Your best score!</h3>
		<p class="noselect stars drop">
			<span *ngIf="popupObject.stars==1">
				<i class="icon-star cards"></i>
				<i class="icon-star-outline cards"></i>
				<i class="icon-star-outline cards"></i>
			</span>
			<span *ngIf="popupObject.stars==2">
				<i class="icon-star cards"></i>
				<i class="icon-star cards"></i>
				<i class="icon-star-outline cards"></i>
			</span>
			<span *ngIf="popupObject.stars==3">
				<i class="icon-star cards"></i>
				<i class="icon-star cards"></i>
				<i class="icon-star cards"></i>
			</span>
		</p>
		<p> Good job! A winner never stops trying.</p>
		<h2><small>Level cleared in </small> {{this.popupObject.time}}<small>s</small></h2>
		<p>
		<button (click)="actionModalWindow('home', '.btn-thumb')" class="rounded drop positive">
			<i class="icon-thumbs-up"></i>
		</button>
		</p>		
	</aside>

	<aside *ngIf="popupType=='reset'" class="popup-box noselect mainbg">
		<h3>Are you sure you want to reset all progress and purchases?</h3>
		<p>This action is irreversible.</p>
		<p>
		<button (click)="actionModalWindow('close', '.btn-thumb1')" class="rounded1 rounded drop negative">
			<i class="icon-thumbs-down"></i>
		</button>
		<button (click)="actionModalWindow('reset', '.btn-thumb2')" class="rounded2 rounded drop positive">
			<i class="icon-thumbs-up"></i>
		</button>
		</p>		
	</aside>

	<!-- ACQUIRE GOODIES -->
	<aside #productPopup id="productPopup" *ngIf="popupType=='product'" class="popup-box noselect mainbg">

		<picture (click)="flipCard(false)">
			<button class="small" (click)="flipCard()"><i class="icon-info-circle original"></i></button>
			<h3>{{this.popupObject.title}}</h3>
			<p>TODO: animated gif explaining functionality</p>
		</picture>

		<button class="small" (click)="flipCard()"><i class="icon-info-circle original"></i></button>		
		<h3>{{this.popupObject.title}}</h3>
		<p>{{this.popupObject.desc}}</p>
		<h3>Use {{this.popupObject.stars}} stars</h3>
		<p>
		<button (click)="actionModalWindow('close', '.btn-thumb1')" class="rounded1 rounded drop negative">
			<i class="icon-thumbs-down"></i>
		</button>
		<button (click)="actionModalWindow('acquire', '.btn-thumb2')" class="rounded2 rounded drop positive">
			<i class="icon-thumbs-up"></i>
		</button>
		</p>

	</aside>

	<!-- USE GOODIES -->
	<aside #productPopup *ngIf="popupType=='usegoodies'" class="popup-box  mainbg">
		<h3 class="noselect">Use goodies</h3>
		<p>&nbsp;</p>
		<div class="grid">
			<ng-container *ngFor="let product of this._service.appProducts, index as i">
				<figure *ngIf="product.goodies > 0"
					(click)="actionModalWindow('use', i)"
					[ngClass]="product.name"
					class="border-dark drop">
					<span class="mark fx-mark" *ngIf="product.goodies > 0" id="product-{{product.name}}">{{product.goodies}}</span>
					<p class="figure-title drop">{{product.title}}</p>
				</figure>
			</ng-container>
		</div>
		<p>
		<button (click)="actionModalWindow('close', '.btn-thumb1')" class="rounded1 rounded drop negative">
			<i class="icon-times"></i>
		</button>
		</p>		
	</aside>



	<!-- PURCHASE STARS -->
	<aside #productPopup id="productPopup" *ngIf="popupType=='buystars'" class="popup-box noselect mainbg">
	
		<h3>Purchase {{this.popupObject.stars}} stars</h3>
		<p class="noselect stars drop">
			<i class="icon-star cards"></i>
			<i class="icon-star cards"></i>
			<i class="icon-star cards"></i>
		</p>
		<h3>{{this.popupObject.price}}</h3>
		<p>
		<button (click)="actionModalWindow('close', '.btn-thumb1')" class="rounded1 rounded drop negative">
			<i class="icon-thumbs-down"></i>
		</button>
		<button (click)="actionModalWindow('purchase', '.btn-thumb2')" class="rounded2 rounded drop positive">
			<i class="icon-thumbs-up"></i>
		</button>
		</p>

	</aside>
</div>


<!-- AUTH -->
<div *ngIf="!this._service.auth" id="auth-container">
	<!-- AUTH -->
	<aside class="popup-box mainbg">
		<h3>Authorization required</h3>
		<p>Please enter a correct phrase</p>
		<p><input #authValue class="enter" type="text"></p>
		<p>			
			<button class="service" (click)="enterAuth()">ENTER</button>
		</p>
	</aside>
</div>


<div #modalOverlay  id="overlay" class="hidden"></div>