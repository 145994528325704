import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { DeviceDetectorService } from 'ngx-device-detector';
import { StorageService } from './storage.service';
import { AiService } from './ai.service';
import { SoundsService } from './sounds.service';

import { gsap  } from "gsap/all";


@Injectable({
  providedIn: 'root'
})

export class PlayerService {
	// *Angular v10
	// all game & app settings	
	// assets/gamesettings.js
	public settings;
	public appSettings;
	public appProducts;
	public storeItems;
	public allStars;
	public allGoodies;
	public goodies;
	public android_ios;
	public cells;
	public AILevel;

	// *Angular v10
	// player related arrays	
	public gameID = 1;
	public allPlayers = []; // all players/ai/empty cells
	public droppables = []; // all cells allowed to be dragged
	public moving = [];	// all cells in moving

	// authorization
	public auth;

	// access to a popup service
	public popup; // popup service

	// access to header
	public header; // header/toolbar component

	// access to moving
	public movingArea; // header/toolbar component	

	// *Angular v10
	// important vars
	public stats; // for stats
	public currentLevel; // game level, starts with 1
	public ticker; // game ticker
	public allowTimeline; // game timeline

	// *Angular v10
	// some necessary shit
	public zIndex = 100; // top index
	private idIndex = 1; // accending index
	public levelActiveIndex = 0; // remember last accctive slide in levels component


	constructor(
		public _storage: StorageService,
		public _sounds: SoundsService,
		private _ai: AiService,
		private _router: Router,
		private deviceService: DeviceDetectorService
		) {

			// *Angular v10
			// add "this player.service" to AI service
			this._ai._service = this;
			this.AILevel = 1;

			// *Angular v10
			// read game settings
			// from assets/gamesettings.js
			this.settings = this._storage.getGameSettings();

			// *Angular v10
			// read app settings
			// from assets/gamesettings.js
			this.appSettings = this._storage.getAppSettings();

			// *Angular v10
			// read products
			// from assets/gamesettings.js
			this.appProducts = this._storage.getAppProducts();

			// get all goodies
			this.allGoodies = 0;
			this.appProducts.filter(item => item.goodies > 0 ? this.allGoodies += item.goodies : null );

			// goodies in-game use
			this.goodies = [];

			// *Angular v10
			// read store items
			this.storeItems = this._storage.getStoreItems();

			// *Angular v10
			// get all stars
			this.allStars = this._storage.getAllStars();

			// *Angular v10
			// allow timeline, set it on true
			// true, when playing
			// false, when it is on pause		
			this.allowTimeline = false;

			// *Angular v10
			// stats for finishing a game
			this.stats = {};
			this.stats.time = 0;

			// *Angular v10
			// android_ios
			this.android_ios = this.getDevice(300,300)[4]['browser'].toLowerCase() === 'safari'?'ios':'android';

			// *Angular v10
			// authorization
			this.auth = this._storage.authorization();

			// *Angular v10
			// set all sound settings
			this._sounds.toggleFX( this.appSettings.fx );
			this._sounds.toggleMusic( this.appSettings.music );

			// init
			console.log("LOAD",this.constructor.name, ' device: '+this.android_ios);
	}

	// ***********************************
	// IN GAME EVENTS ********************
	// ***********************************

	// EVENT start a game
	eventStartGame() {
		// *Angular v10

		// restore gameplay settinga
		this._storage.restoreGameplay();

		// check for Level's handicaps
		// TO-DO ??? ??? ???
		let gameplay = {};
		this._storage.changeGameplay( gameplay );

		// check for cell's types
		this.cells = {};
		this.settings.vars.allCells.forEach( 
			// example: {player: 2, ai: 2, empty: 1, field: 0, hole: 1}
			el => this.cells[el] = this.getWhois(el).length>0 ? this.getWhois(el).length : 0
		);

		// all players should be at this point, already set
		// init all players to a proper colors and positions
		// the first time ONLY !
		let getPlayers = this.getAllPlayer();
		for (var i in getPlayers) {
		  	getPlayers[i].runComponent();    
		}

		// goodies in-game use
		this.goodies = [];

    	// create a ticker
    	this.createTicker();

	}

	// EVENT refesh positions
	// recalculate all positions & sizes
	eventRefreshPositions() {
		// *Angular v10
		// update coordinates on ALL cells within a stage
		let getPlayers = this.getAllPlayer();
		for (var i in getPlayers) {
		  // refresh all players
		  getPlayers[i].refreshPosition();
		}
		let getMovers = this.getMovings();
		for (var i in getMovers) {
		  // refresh scale in moving objects
		  getMovers[i].componentRef.instance.movingObj.scale = this.settings.gridPosition.SVGblob;
		  getMovers[i].componentRef.instance.refreshPosition();
		}
	}

	// EVENT stop a game
	// it triggers on ngDestroy Game Component
	// when you finish a game or use HOME button
	eventStopGame() {
		// *Angular v10
	    // clear the timeline	    
	    this.clearTicker();
	    this.allowTimeline = false;
		// reset all players arrays
		// remove it from global scope
		this.allPlayers = [];
		this.droppables = [];
		this.moving = [];
		// reset stats
		this.stats = {};
		this.stats.time = 0;
	}

	// EVENT Player has won a game
	eventPlayerWon() {
		// *Angular v10
		// PLAYER WON
		if (this.allowTimeline) {
			// delay for a second
			this.allowTimeline = false;
			return;
		}
		// stop a game
		this.pauseTicker();
		this.clearTicker();
		this.updateGameTimer();
		// set a wining stats
		// time, stars, and check for best time
		let data = {};		
		data['time'] = this.stats.time;
		data['status'] = 'done';
		data['stars'] = this._storage.getStarsPlayersData(this.stats.time,this.gameID);
		data['best'] = this._storage.compareBestScore(this.stats.time, this.gameID);
		data['type'] = data['best']?'best':'win';
		// update player with better score if necessary
		this.updatePlayerData(this.gameID,'done',this.stats.time);
		// show a popup
		this.popupCreate(data);
	}

	// EVENT Player lost a game
	eventPlayerLost() {
		// *Angular v10
		// A.I. WON
		if (this.allowTimeline) {
			// delay for a second
			this.allowTimeline = false;
			return;
		}
		// stop a game
		this.pauseTicker();
		this.clearTicker();
		this.updateGameTimer();
		// set a loosing stats
		let data = {};
		data['type'] = 'lost';
		data['time'] = this.stats.time;
		// show a popup
		this.popupCreate(data);
	}

	// EVENT goodies from App Products has been acquired
	eventGoodiesAquired(index) {
		// *Angular v10
		if ( this._storage.acquireAppProduct( index ) ) {
			// enough stars			
			// update appProducts
			this.appProducts = this._storage.getAppProducts();
			// update a number of goodies
			this.allGoodies = 0;
			this.appProducts.filter(item => item.goodies > 0 ? this.allGoodies += item.goodies : null );
			// update stars
			this.allStars = this._storage.getAllStars();
			// show toast
			this.createToast('acquire_success');
			this.soundFX ( 'buy' );
		} else {
			// not enough stars
			this.shakeMyScreen( '.grid' );
			this.createToast('notenoughstars');
			this.soundFX ( 'notallowed' );
		}
	}

	// EVENT a user has purchased stars from a store
	eventPurchaseStar(index) {
		// *Angular v10
		if ( this._storage.purchaseStars( index ) ) {
			// enough stars			
			// update products
			this.appProducts = this._storage.getAppProducts();
			// update stars
			this.allStars = this._storage.getAllStars();
			// show toast
			this.createToast('stars_success');
			this.soundFX ( 'buy' );

		} else {
			// denied credit card
			this.shakeMyScreen( '.grid' );
			this.createToast('stars_denied');
			this.soundFX ( 'notallowed' );
		}
	}
	// EVENT a user used a goodie
	eventGoodieUse(index) {
		// *Angular v10
		if (this.goodies[index]) {
			// already applied
			this.createToast('use_already');
			this.soundFX ( 'notallowed' );
			return;
		}
		if ( this._storage.useAppProduct( index ) ) {
			// enough stars			
			// update products
			this.appProducts = this._storage.getAppProducts();
			// update a number of goodies
			this.allGoodies = 0;
			this.appProducts.filter(item => item.goodies > 0 ? this.allGoodies += item.goodies : null );
			
			// update moving blobs to a different speed if necessary
			for (var a in this.moving) {
				// componentRef.instance -> blob.component
				this.moving[a].componentRef.instance.updateSpeed( this.settings.gameplay.timing );
			}

			// remember used goodies
			this.goodies[index] = 'alredady bought';

			// show icon in header
			this.header.showGoodies( this.appProducts[index].name );

			// show toast
			this.createToast('use_success');
			this.soundFX ( 'buy' );

		} else {
			// something went wrong
			this.createToast('use_denied');	
			this.soundFX ( 'notallowed' );		
		}

	}
	// EVENT settings are being changed
	eventChangeAppSettings(event, type) {
		// *Angular v10
		// change/save app settings
		this.appSettings[type] = event;
		this.appSettings = this._storage.changeAppSettings( this.appSettings );
	}

	// ***********************************
	getAllStars() {
		// *Angular v10
		// read all stars
		this.allStars = this._storage.getAllStars();
		return this.allStars;	
	}
	// ***********************************	
	getPlayerData() {
		// *Angular v10
		// player data for LEVELS
		// read progress form storage
		// and update playersData levels
		// return a fully updated playersData
		return this._storage.getPlayersData();
	}
	getStagesNames() {
		// *Angular v10
		// get a scene names
		// read from data.service
		return this._storage.getStagesNames();
	}
	getStageFromLevelNumber (level: number) {
		// *Angular v10
		// get a stage based on level number
		const stage = this.getStagesNames();		
		for (let i in stage) {
			if ( stage[i].start <= level && stage[i].end >= level) {
				// console.log(i, stage[i].allow, stage[i].start, stage[i].end);	
				return Number(i);
			}
		}
		// default is stage 0;
		return 0;
	}
	getPlayerDataByIndex(level:number) {
		// *Angular v10
		// get a playersData by level number
		return this._storage.getPlayerDataByIndex(level);		
	}
	updatePlayerData(level, status, time) {
		// *Angular v10
		// update a level, if it has a better time
		this._storage.updateLevelPlayerWon(level, status, time);
	}
	updateAppProducts() {
		this.appProducts = this._storage.getAppProducts();
		return 	this.appProducts;
	}
	// ***********************************
	setPlayer(id, component) {
		// *Angular v10
		if(!id || !component) { return; }

		// register a player
		this.allPlayers[id] = component;
		if ( this.settings.gameplay.allowToMove[component.whois] ) {
			// register main element as droppables
			this.droppables[id] = component.idObj;	
		} else {
			// I guess filed and hole cannot be droppables
		}
		
	}
	removePlayer(id) {
		// *Angular v10
		// delete a player from array
		delete this.allPlayers[id];
	}
	getDroppables(id=null){
		// *Angular v10
		if (id) {
			return this.droppables[id]?this.droppables[id]:[]; // native element
		} else {
			return this.droppables; // array of elements
		}
	}
	getHighlighted(){
		// *Angular v10
		// return all element contains "highlight" class
		for (let i in this.droppables) {
			if (this.droppables[i].nativeElement.classList.contains(this.settings.vars.highClass)) {
				return [i, this.droppables[i]];
			};
		}
		return false;
	}

	getGridPositions (x=0, y=0) {
		// *Angular v10
		// return an absolute position based on grids coordinates
		// e.g. x2,y4 => x40,y80
		// based on grid size and orientation
		let posX = 0;
		let posY = 0;

		// portrait is default, landscape is other way around
		if(this.settings.gridPosition.orientation == 'portrait') {
			// this is portait
			// we do not change enything
			posX = Math.round( x * this.settings.gridPosition.rectWidth );
			posY = Math.round( y * this.settings.gridPosition.rectHeight );
		} else {
			// this is landscape
			// change XY to YX
			// because coordinates are always as X,Y
			posX = Math.round( y * this.settings.gridPosition.rectWidth );
			posY = Math.round( x * this.settings.gridPosition.rectHeight );

			// mirror Y position
			// it makes more sense
			if (this.settings.grids.mirrorY) {				
				posY = this.settings.gridPosition.stageH - posY;
			}
		}
		// return calculate X and Y position as Array
		return [posX,posY];
	}

	getRandomCircumference(rad=50) {
		// *Angular v10
		// return random x,y in Circumference
		let angle = Math.random()*Math.PI*2;
		let x = Math.cos(angle)*rad;
		let y = Math.sin(angle)*rad;
		return [x,y];
	}

	getCircumference(rad=50, angle=0) {
		// *Angular v10
		// return x,y in Circumference
		let x = Math.cos(angle)*rad;
		let y = Math.sin(angle)*rad;
		return [x,y];
	}

	shakeMyScreen(obj:String){
		// *Angular v10
		// shake a screen
		// obj:String, DOM class name or ID
		let dur = 0.075;
		let shake = gsap.timeline();		
		shake.set(obj,{x:0});
		shake.set(obj,{x:4,delay:dur});
		shake.set(obj,{x:-2,delay:dur});
		shake.set(obj,{x:1,delay:dur});
		shake.set(obj,{x:0,delay:dur});
	}


	getDevice(width, height) {
		// *Angular v10
		// device info
		// https://www.npmjs.com/package/ngx-device-detector
		let deviceInfo = this.deviceService.getDeviceInfo();
		let isMobile = this.deviceService.isMobile();
		let isTablet = this.deviceService.isTablet();
		let isDesktopDevice = this.deviceService.isDesktop();

		// additionl check for testing and
		// switching resolution while testing
		let isSmallDevice = false;		
		if (this.settings.gridPosition.orientation == 'portrait') {
			// in portrait, check the width;
			if (width < 650) { isSmallDevice = true; }
		} else {
			// in landscape, check the height;
			if (height < 650) { isSmallDevice = true; }	
		}
		// return an array
		return [isSmallDevice, isMobile, isTablet, isDesktopDevice, deviceInfo];

	}


	setScaleFactor(width:number, height:number) {
		// *Angular v10
		// ********************************
		// get a pecantage between current grid size and max grid size
		let min = this.settings.grids.min;
		let max = this.settings.grids.max;
		let comparison = ( 100 * Math.max(width,height) ) / max;

		// go through scale
		// @parameter SVGscale: factor for main outer & inner cell animation
		// @parameter SVGroll: % for roll over & highlight fx
		// @parameter SVGblob: factor blob size
		// @parameter SVGradius: size of the cells radius
		// @parameter fontSize: font size for counters values in EM

		if (comparison > 90) {
			// desktop, iPad, Nexus 7,...
			this.settings.gridPosition.SVGscale = 0.8;
			this.settings.gridPosition.SVGroll = '48%';
			this.settings.gridPosition.SVGblob = 0.4;
			this.settings.gridPosition.SVGradius = 40;
			this.settings.gridPosition.fontSize = "14px";

		} else if (comparison > 80) {
			// small notebooks
			this.settings.gridPosition.SVGscale = 0.7;
			this.settings.gridPosition.SVGroll = '40%';
			this.settings.gridPosition.SVGblob = 0.38;
			this.settings.gridPosition.SVGradius = 40;
			this.settings.gridPosition.fontSize = "13px";

		} else if (comparison > 70) {
			// iPhone X
			// S20 ultra
			// Pixel XL
			this.settings.gridPosition.SVGscale = 0.55;
			this.settings.gridPosition.SVGroll = '38%';
			this.settings.gridPosition.SVGblob = 0.33;
			this.settings.gridPosition.SVGradius = 29;
			this.settings.gridPosition.fontSize = "13px";

		} else if (comparison > 60) {
			// iPhone 8 plus
			// S20+
			// Pixel 2,3
			this.settings.gridPosition.SVGscale = 0.55;
			this.settings.gridPosition.SVGroll = '38%';
			this.settings.gridPosition.SVGblob = 0.32;
			this.settings.gridPosition.SVGradius = 28;
			this.settings.gridPosition.fontSize = "12px";

		} else if (comparison > 50) {
			// iPhone 6,7,8
			this.settings.gridPosition.SVGscale = 0.5;
			this.settings.gridPosition.SVGroll = '35%';
			this.settings.gridPosition.SVGblob = 0.28;
			this.settings.gridPosition.SVGradius = 25;
			this.settings.gridPosition.fontSize = "11px";

		} else {
			// old iPhones 5, screen up to 340px
			this.settings.gridPosition.SVGscale = 0.4;
			this.settings.gridPosition.SVGroll = '30%';
			this.settings.gridPosition.SVGblob = 0.25;
			this.settings.gridPosition.SVGradius = 20;
			this.settings.gridPosition.fontSize = "10px";

		}
	}

	levelAllowed(id) {
		// *Angular v10
		// check if level is allowed
		if (this.settings.test.allowSkipLevels === true) { return true; }
		let stage = this.getStagesNames();		
		for (let i in stage) {
			if ( stage[i].start <= id && stage[i].end >= id) {
				return stage[i].allow;
				// console.log(i, stage[i].allow, stage[i].start, stage[i].end);	
			}
		}
		return false;
	}


	getInvertedPlayer (whois:string) {
		// *Angular v10
		// invert between player/ai
		// when a battle is lost, a player must be inverted
		if (whois==='player') {
			return 'ai';
		} else if (whois==='ai') {
			return 'player';
		} else {
			return whois;
		}
	}

 	returnSplitNumber(number=0) {
 		// *Angular v10
 		// first number rounds up, second to rounds down (in case of 5.5, 5.5 => 6, 5)
		return new Array( Math.ceil(number), Math.floor(number));
	}
	returnRandomID(name:string) {
		// *Angular v10
		if(!name) { name = 'id'; }
		// better sollution 
		return name + this.idIndex++;
		// return "id"+gsap.utils.random(100000, 2000000, 1);
	}
	returnDecimalNumber(number=0) {
		// *Angular v10
		// return only 2 decimals
		return Number(number).toFixed(2);
	}
	returnBetween(min=0,max=1,value=1) {
		// *Angular v10
		// generates a random number
		return gsap.utils.random(min, max, value);
	}
	returnBetweenMixMax(min=0,max=1,value) {
		// *Angular v10
		// Clamp a value to fit within a specific range 
		return gsap.utils.clamp(min, max, value);
	}
	returnBetweenTwoNumbers(j=0,k=1) {
		// *Angular v10
		// return all nubers between j and k 
		return Array
			.apply(null, Array((k - j) + 1))
			.map(function(_, n){ return n + j; }); 
	}
	returnCircumference (r=1) {
		// *Angular v10
		// return all nubers between j and k 
		var angle = Math.random()*Math.PI*2;
		return Array(Math.cos(angle)*r, Math.sin(angle)*r);
	}

	// array of players
	getPlayer(id) {
		// *Angular v10
		// all players on a stage
		// @object: Component
		return this.allPlayers[id];	
	}
	getAllPlayer() {
		// *Angular v10
		// all players on a stage
		// @array: Component
		return this.allPlayers;	
	}
	getWhois(whois) {
		// *Angular v10
		// return all players based on "whois" parameter
		// e.g. whois="ai" returns all AI players
		// @array
		let ply = [];
		for (var p in this.allPlayers) {
			if (this.allPlayers[p].whois === whois) {
				ply.push(this.allPlayers[p]);
			}
		}
		return ply;
	}
	getMovings() {
		// *Angular v10
		// all moving players on a stage
		// @array.componentRef.instance: Component
		return this.moving;
	}
	getPlayersInWar() {
		// *Angular v10
		// return all players who are in war
		// @array
		let ply = [];
		for (var p in this.allPlayers) {
			if (this.allPlayers[p].fighting === true) {
				ply.push(this.allPlayers[p]);
			}
		}
		return ply;	
	}

	getURLroute() {
		// *Angular v10
		// @array
		let url = this._router.url.split('#');
		// [0]: url
		// [1]: #hash
		return url;
	}
	getURLforGame() {
		// *Angular v10
		// for moving blobs
		return this.getURLroute()[0] + '#move-' + this.returnBetween(100000,999999);
	}
	getURLforPopup() {
		// *Angular v10
		// for popups
		return this.getURLroute()[0] + '#popup-' + this.returnBetween(100000,999999);
	}




	// ***************************************
	// TICKER FOR A GAME
	createTicker() {
	  	// *Angular v10
	  	// reset for a new game
	    this.stats = {};
	    this.stats.time = 1;
	    this.allowTimeline = true;
	    this.moving = [];
	    // create a timeline ticker
	    // use a gsap for that
	    console.log("%c Create TICKER...  ", "background: #ddd; color: #333;");
	    console.log("%c Using A.I. " + this.AILevel, "background: #ddd; color: #333;");


	    // ticker repeat itself every second
	    this.ticker = gsap.timeline({repeat: -1, repeatDelay: 0, duration: this.settings.gameplay.counters.tickerSpeed, callbackScope:this, onRepeat: function() {
			// update timer
			this.updateGameTimer();

			// update ticker
			this.updateTicker();

			if(this.stats.time > 1) {
				// update AI, after one second
				this._ai.updateAI();

				// check status of the game
				this.checkGameStatus();
			}  

			// every cycle add a number to a clock
			this.stats.time = this.stats.time + this.settings.gameplay.counters.tickerSpeed; 

	    }});

	    // start of the game, ready, steady, go!
	    // countdown or button for start the game
	    if ( this.appSettings.countdown === true) {
	    	this.popupCreate({type:'ready'});
	    } else {
	    	this.popupCreate({type:'go'});
	    }
	    

	}

	updateTicker() {
		// *Angular v10
		// repeat at every ticker (1s)
		let getPlayers = this.getAllPlayer();
		for (var i in getPlayers) {
			// update all players on a stage
			getPlayers[i].updateCounter();
		}
	}

	updateGameTimer() {
		// *Angular v10
		let val = Number(this.stats.time);
		let cifra = '';
		if ( val < 10 ) { 
			cifra = '00'
		} else if ( val < 100) {
			cifra = '0';
		}
		cifra = cifra + String(val);
		// use gsap for rendering
		// it is faster
		gsap.set( "#trigerCounter", {text:{value: cifra}} );

	}

	playTicker() {
		// *Angular v10
		// if there is a timeline
		if(!this.ticker) { return; }
		
		this.allowTimeline = true;
		this.ticker.play();
		for(var i in this.allPlayers) {
			// play timeline in all players on a stage
			this.allPlayers[i].timeline.play();
		}
		for(var i in this.moving) {
			// play timeline in all moving players
			this.moving[i].componentRef.instance.timeline.play();
		}
		// reset rotate pause button
		this.header.rotatePauseButton(false);
		console.log(" -play ticker");
	}

	pauseTicker() {
		// *Angular v10
		// if there is a timeline
		if(!this.ticker) { return; }

		this.allowTimeline = false;
		this.ticker.pause();
		for(var i in this.allPlayers) {
			// pouse timeline in all players on a stage
			this.allPlayers[i].timeline.pause();
		}
		for(var i in this.moving) {
			// pouse timeline in all moving players
			this.moving[i].componentRef.instance.timeline.pause();
		}
		// reset rotate pause button
		this.header.rotatePauseButton(true);
		console.log(" -pause ticker");
	}

	toggleTicker() {
		// *Angular v10
		// if there is a timeline
		if(!this.ticker) { return; }

		// togle between states
		if (this.ticker.paused()) { 
			this.playTicker();
		} else {
			this.pauseTicker();
		}
	}

	clearTicker() {
		// *Angular v10
		// if there is a timeline
		if(!this.ticker) { return; }
		// clear ticker
		this.ticker.pause();
		this.ticker.clear();
		this.ticker.kill();
		this.ticker = null;		
		// do not allow timeline
		this.allowTimeline = false;

		// ticker is stopped
		console.log(" -kill ticker " )
	}

	checkGameStatus() {
		// *Angular v10
		// check for: no empty cells on a stage
		if (this.getPlayersInWar().length > 0) { return; }
		// check for: no-one is in a war with each other
		if (Object.keys(this.getMovings()).length > 0) { return; }
		// check for: no-one is moving
		if (this.getWhois('empty').length > 0) { return; }

		// get all players player/ai
		let ai = this.getWhois('ai').length;		
		let player = this.getWhois('player').length;

		// anybody won?
		if (ai == 0) { this.eventPlayerWon();	}
		if (player == 0) { this.eventPlayerLost(); }
	}

	// ***************************************
	// POPUPS
	// create popup
	popupCreate(data) {
		// *Angular v10
		if(!data) { return; }
		this.popup.openModalWindow(data);	
	}
	popupClosed() {
		// *Angular v10		
		// resume a timeline except a game is paused
		this.playTicker();
	}

	popupOpened() {
		// *Angular v10
		// pause a timeline
		this.pauseTicker();	
	}

	createToast(type) {
		// *Angular v10
		// all toasts are the same 
		if(!type) { return; }
		this.popup.openToast(type);
	}

	resetTheProgress() {
		// *Angular v10
		this._storage.resetProgress();
		this._storage.resetTransaction();
		this._storage.init();
		this.appProducts = this._storage.getAppProducts();
		this.allStars = this._storage.getAllStars();
		this.createToast('resetprogress');
		setTimeout( () => { location.reload(); }, 250);
	}

	testpopup() {
		// just for testing
		let obj = {};
		obj['type'] = 'reset';
		obj['time'] = 120;
		this.popup.openModalWindow(obj);

	}


	// ***************************************
	// SOUNDS & MUSIC
	soundFX ( fx ) {
		if (!fx) { return; }
		this._sounds.playSoundFX( fx );
	}
	soundsHome () {
		this._sounds.playHomeScreenMusic();
	}
	soundsStage ( stage ) {
		this._sounds.playStageMusic( stage );
	}
	updateSoundsStage ( percent ) {
		this._sounds.updateStageMusic( percent );
	}

}




