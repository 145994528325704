import { Component, ViewChild, ElementRef, Renderer2, OnInit } from '@angular/core';
import { PlayerService } from './../services/player.service';

import { gsap } from "gsap/all";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

	public checkPortrait;
	public checkMusic;
	public checkFx;
	public checkHD;
	public checkVersion;
	public checkCountdown;

	@ViewChild('mainScreen') mainScreen: ElementRef;

	constructor( 
		public _service: PlayerService,
		private _renderer: Renderer2
	) { }

	ngOnInit(): void {	
		// *Angular v10
		// read settings
		this.checkPortrait = this._service.appSettings.portrait;
		this.checkMusic = this._service.appSettings.music;
		this.checkFx = this._service.appSettings.fx;
		this.checkHD = this._service.appSettings.hd;
		this.checkVersion = this._service.appSettings.version;
		this.checkCountdown = this._service.appSettings.countdown;
		
		// init
		this._service.soundsHome();
		console.log("%c INIT: " + this.constructor.name, "background: #eee; color: #333;");
	}
	
	ngOnDestroy() {
		// *Angular v10
		console.warn("DESTROY", this.constructor.name, );
	}

	ngAfterViewInit() {
		// *Angular v10
		// fade in mainScreen
	    gsap.set(this.mainScreen.nativeElement, { opacity: 0});
	    this._renderer.removeClass(this.mainScreen.nativeElement, this._service.settings.vars.hiddenClass )
	    gsap.to(this.mainScreen.nativeElement, { opacity: 1, delay: 0.5, duration: 0.5 });
  	}

	changedInput( getEvent ){
		// *Angular v10
		// update/save app settings in service
		let event = getEvent.target.checked;
		let type = getEvent.target.value;
		// update/save app settings in service
		this._service.eventChangeAppSettings(event, type);

		// update in game sound/music
		if (type === 'music') {
			if ( event ) { this._service._sounds.toggleGameSounds( true ); }
			this._service._sounds.toggleMusic( event );
		}
		if (type === 'fx') {
			if ( event ) { this._service._sounds.toggleGameSounds( true ); }
			this._service._sounds.toggleFX( event );
		}
	}

	popup() {
		this._service.testpopup();
	}

	

}
