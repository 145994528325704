<app-header [_type]="'mainHeader'"></app-header>
<main #mainScreen class="hidden">

	<h1 class="upper noselect">You have <span class="fx-star">{{this._service.allStars}}</span> stars</h1>
	<p class="noselect">
		<i class="icon-heart inline-text"></i>
		 Exchange stars with the adrenaline boosters to help you win a stage!
	</p>

	<aside class="grid goodies">

		<figure *ngFor="let product of this._service.appProducts, index as i"
			(click)="buyProdusts(product.name, i)"
			[ngClass]="product.name"
			class="border-dark products">
			<span class="mark fx-mark" *ngIf="product.goodies > 0" id="product-{{product.name}}">{{product.goodies}}</span>
			<h2 class="drop">{{product.title}}</h2>
			<p>{{product.stars}} 
				<i class="icon-three-stars three"></i>
			</p>
		</figure>

	</aside>

	<h1 class="noselect">Buy more stars<br><small>(test for in-app purchase)</small></h1>

	<aside class="grid carts">

		<figure *ngFor="let item of this._service.storeItems, index as i"
			(click)="buyStars(item.id, i)" 
			class="gold reflect border-dark">
			<h2>{{item.stars}}</h2>
			<h3><i class="icon-three-stars three"></i></h3>
			<p>{{item.price}}</p>
		</figure>

	</aside>

	<p class="noselect">&nbsp;</p>
</main>

