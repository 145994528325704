import { Component, ViewChild, ElementRef, Renderer2, OnInit } from '@angular/core';
import { PlayerService } from './../services/player.service';

import { gsap } from "gsap/all";


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

	public products;
	public items;


	@ViewChild('mainScreen') mainScreen: ElementRef;

	constructor( 
		public _service: PlayerService,
		private _renderer: Renderer2 
	) { }

	ngOnInit(): void { 
		// *Angular v10
		// init
		this._service.soundsHome();
	  console.log("%c INIT: " + this.constructor.name, "background: #eee; color: #333;");
	}

	ngAfterViewInit() {
		// *Angular v10
		// fade in mainScreen
	    gsap.set(this.mainScreen.nativeElement, { opacity: 0});
	    this._renderer.removeClass(this.mainScreen.nativeElement, this._service.settings.vars.hiddenClass )
	    gsap.to(this.mainScreen.nativeElement, { opacity: 1, delay: 0.5, duration: 0.5 });
  	}

	ngOnDestroy() {
		// *Angular v10
		console.warn("DESTROY", this.constructor.name);
	}

	buyProdusts(goody, index) {
		// *Angular v10
		if(!goody) { return; }
		let obj = {};
		obj['type'] = 'product';
		obj['title'] = 	this._service.appProducts[index].title;
		obj['desc'] = 	this._service.appProducts[index].desc;
		obj['stars'] = 	this._service.appProducts[index].stars;
		obj['index'] = 	index;

		// open popup
		this._service.popupCreate(obj);

	}

	buyStars(goody, index) {
		// *Angular v10
		if(!goody) { return; }
		let obj = {};
		obj['type'] = 'buystars';
		obj['stars'] = 	this._service.storeItems[index].stars;
		obj['price'] = 	this._service.storeItems[index].price;
		obj['id'] = goody;
		obj['index'] = 	index;

		// open popup
		this._service.popupCreate(obj);

	}

}
