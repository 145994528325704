import { Injectable } from '@angular/core';

// assets/gamelevels.js
declare const returnGameLevels: any;

// assets/gamesettings.js
declare const returnGameSettings: any;
declare const returnAppSettings: any;
declare const returnAppProducts: any;
declare const returnStoreItems: any;

// assets/matrixanimation.js
declare const returnMatrixAnimation: any;
declare const returnMatrixBg: any;
declare const returnMatrixBlob: any;
declare const returnMatrixHole: any;
declare const returnMatrixFlip: any;
declare const returnMatrixField: any;
declare const returnMatrixJigg: any;



@Injectable({
  providedIn: 'root'
})
export class DataService {

	public gameStages;
	public gameLevels;
	public gameSettings;
	public gameplayDefaults;
	public appSettings;
	public appProducts;
	public storeItems;

	constructor() { 
		// *Angular v10
		// get stage names & levels
		// assets/gamelevels.js
		this.gameStages = returnGameLevels().stage; // json
		this.gameLevels = returnGameLevels().levels; // json

		// get settings
		// assets/gamesettings.js
		this.gameSettings = returnGameSettings(); // object
		// add matrix animation from assets/JS
		// matrix animation
		// assets/matrixanimation.js
		this.gameSettings.matrix = {};
		// get a matrix animation from assets library
		// length must be 120 frames !!!
		this.gameSettings.matrix.path = returnMatrixAnimation(); // array 
		this.gameSettings.matrix.bg = returnMatrixBg();  // string
		this.gameSettings.matrix.blob = returnMatrixBlob();  // array
		this.gameSettings.matrix.fields = returnMatrixHole();  // array
		this.gameSettings.matrix.hole = returnMatrixHole();  // array
		this.gameSettings.matrix.flip = returnMatrixFlip();  // array
		this.gameSettings.matrix.field = returnMatrixField();  // array
		this.gameSettings.matrix.jigg = returnMatrixJigg();  // array
		this.gameSettings.matrix.all = this.gameSettings.matrix.path.length - 1; // number

		// *** deepClone *** object.gameplay 
		// Imporatant: this should be used only for value Numbers and Strings
		this.gameplayDefaults = (JSON.stringify( returnGameSettings().gameplay ));
		
		// app settings
		// get default settings
		this.appSettings = returnAppSettings(); // json

		// app goodies 
		// get default settings
		this.appProducts = returnAppProducts(); // json

		// store items 
		// get items from stores
		this.storeItems = returnStoreItems(); // json

	  console.log("LOAD", this.constructor.name);

  	}




}
