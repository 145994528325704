<app-header [_type]="'mainHeader'"></app-header>
<main #mainScreen class="hidden">
	<h1>Settings <small>(works only in app)</small></h1>



    <p>
      <label class="ui_checkbox">
        <input [checked]="this.checkPortrait" type="checkbox" value="portrait" (change)="changedInput($event)">
        <span  class="noselect"></span>
        Lock screen to portrait
      </label>
    </p>
    <p>
      <label class="ui_checkbox">
        <input [checked]="this.checkMusic" type="checkbox" value="music" (change)="changedInput($event)">
        <span  class="noselect"></span>
        Background music
      </label>
    </p>
    <p>
      <label class="ui_checkbox">
        <input [checked]="this.checkFx" type="checkbox" value="fx" (change)="changedInput($event)">
        <span  class="noselect"></span>
        Sound effects
      </label>
    </p>
    <p>
      <label class="ui_checkbox">
        <input [checked]="this.checkHD" type="checkbox" value="hd" (change)="changedInput($event)">
        <span  class="noselect"></span>
        HD animations (high battery consumption)
      </label>
    </p>
    <p>
      <label class="ui_checkbox">
        <input [checked]="this.checkCountdown" type="checkbox" value="countdown" (change)="changedInput($event)">
        <span  class="noselect"></span>
        Start the game with countdown
      </label>
    </p>



    <p>&nbsp;</p>
    <p class="noselect">Reset all the progress you've made so far and start from scratch.</p>
    <button class="service" (click)="popup()">Reset progress</button>
    <p><small><em>Copyright AnimType | App version v{{this.checkVersion}}</em></small></p>

</main>
