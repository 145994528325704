<figure #idObj class="{{coordX}}x{{coordY}} obj marg hidden" id="{{id}}">
	
	<svg class="abs svg noselect main-character" height="100" width="100" viewBox="0 0 100 100" preserveAspectRatio="none" >

		<!-- highlight & backgrounds -->
		<g class="noselect">
			<ellipse #rollover class="noselect rollover" cx="50%" cy="50%" rx="40%" ry="40%" fill="transparent" stroke="gray" stroke-width="10" stroke-linecap="round" />
		</g>

		<!--dragging animation -->
		<g class="dragging noselect">
			<ellipse #dragMe class="dragging-me noselect fill_color" cx="50%" cy="50%" rx="50%" ry="50%" fill="gray"  />
			<path #draggingLine class="dragging-line noselect stroke_color" d="M50 50 L50 50" stroke="gray" stroke-width="2" stroke-linecap="round" stroke-dasharray="5,5" />
		</g>

		<g class="body noselect">
			<!--cell background -->
			<path #animeBG class="fill_color war_color" d="M50 50 L50 50" fill="gray" stroke="gray" stroke-width="0" />			
			<path #anime class="cell_color" d="M50 50 L50 50" fill="gray" stroke="gray" stroke-width="0" />		   
		   	<!-- main counter -->			
			<text #bodyCount class="body-count noselect text_color" x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="black"> </text>
			<!-- war counter -->	
			<text #myCount class="my-count noselect war-text_color" x="30%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="black"> 111</text>
			<text #foeCount class="foe-count noselect war-text_color" x="70%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="black"> 222</text>
			<!-- bust counter -->	
			<text #addCount class="bust-count noselect bust-text_color" x="50%" y="20%" dominant-baseline="middle" text-anchor="middle" fill="black">+10</text>
		</g>

	</svg>

	<!--drag a cell  -->
	<svg #drag class="abs drag drag-character" height="100" width="100" viewBox="0 0 100 100" preserveAspectRatio="none">
		<ellipse cx="50%" cy="50%" rx="40" ry="40" fill="none" />
	</svg>

	<!--[deprecated] -->
	<ng-container #moveContainer></ng-container>
	
</figure>