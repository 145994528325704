<figure #idObj class="{{coordX}}x{{coordY}} {{whois}} obj marg fields noselect hidden" id="{{id}}">	
	<svg class="abs svg noselect main-character" height="100" width="100" viewBox="0 0 100 100" preserveAspectRatio="none" >
		<g class="move noselect">
			<path #animeFX class="noselect fill_orbital" d="M50 50 L50 50" stroke-width="0" />			
			<path #animeBG class="noselect fill_color" d="M50 50 L50 50" fill="gray" stroke="gray" stroke-width="0" />

			<ellipse #animeLite1 class="noselect fill_lite" cx="50%" cy="50%" rx="2" ry="2" />						
			<ellipse #animeLite2 class="noselect fill_lite" cx="50%" cy="50%" rx="2%" ry="2%" />						
			<ellipse #animeLite3 class="noselect fill_lite" cx="50%" cy="50%" rx="2%" ry="2%" />						
			<ellipse #animeLite4 class="noselect fill_lite" cx="50%" cy="50%" rx="2%" ry="2%" />						
			<ellipse #animeLite5 class="noselect fill_lite" cx="50%" cy="50%" rx="2%" ry="2%" />						
			<ellipse #animeLite6 class="noselect fill_lite" cx="50%" cy="50%" rx="2%" ry="2%" />						
			<ellipse #animeLite7 class="noselect fill_lite" cx="50%" cy="50%" rx="2%" ry="2%" />						
			<ellipse #animeLite8 class="noselect fill_lite" cx="50%" cy="50%" rx="2%" ry="2%" />						
			<ellipse #animeLite9 class="noselect fill_lite" cx="50%" cy="50%" rx="2%" ry="2%" />						

		</g>
	</svg>
</figure>
