import { Component, ViewChild, ElementRef, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { PlayerService } from './../../../services/player.service';
import { gsap, Draggable } from "gsap/all";
gsap.registerPlugin(Draggable);

@Component({
  selector: 'app-blob',
  templateUrl: './blob.component.html',
  styleUrls: ['./blob.component.scss']
})
export class BlobComponent implements OnInit {

		// main object
		public movingObj;

		// timeline
		private timeline;
		private hitHole = false;
		private hitField = false;
		private hitFlip = false;
		private blobSpeed = 0;
		private hole;
		private field;
		private flip;


		// native elements selectors
		@ViewChild('mover') mover: ElementRef;
		@ViewChild('anime') anime: ElementRef;
		@ViewChild('text') text: ElementRef;
		@ViewChild('fx') fx: ElementRef;

	constructor(
		private _service: 	PlayerService,
		private _refresh: ChangeDetectorRef
	) { }



	ngOnInit() {
		// *Angular v10
		// force refresh changes
		// just in case, if there is a DOM delay
		// for injecting a BlobComponent
		// and removing a BlobComponent
		this._refresh.detectChanges();
	}

  	ngAfterViewInit() {
  		// *Angular v10
  		this.blobSpeed = this.movingObj.timing;
  		this.animateBlob( false, false );
  		// get fields
  		this.hole = [];
  		this.field = [];
  		this.flip = [];
			this._service.getWhois('hole').forEach( el => this.hole.push(el.id) );
			this._service.getWhois('field').forEach( el => this.field.push(el.id) );
			this._service.getWhois('flip').forEach( el => this.flip.push(el.id) );


	}

	animateBlob( refresh, progress ) {
		// *Angular v10
		// refresh position
		if ( refresh ) {
			// kill a timeline
			this.timeline.clear();
			this.timeline.kill();
		}
		// calculate coordinates		
		let fromID = this._service.getPlayer(this.movingObj.fromID);
		let targetID = this._service.getPlayer(this.movingObj.targetID);
		let fromIDcoordinates = this._service.getGridPositions( fromID.coordX, fromID.coordY );
		let targetIDcoordinates = this._service.getGridPositions( targetID.coordX, targetID.coordY );
		let x1 = fromIDcoordinates[0];
		let y1 = fromIDcoordinates[1];
		let x2 = targetIDcoordinates[0];
		let y2 = targetIDcoordinates[1];
		// 
  		//    (O) x1,y1 <- MY CELL
  		//      \
  		//      (X)  <- offset1, start blob position, dot['x1'], dot['y1']
  		//        \
  		//         \
  		//          \
  		//           \
  		//            \
  		//             \
  		//              \
  		//              (X) <- offset2, end blob position, dot['x2'], dot['y2']
  		//                \
  		//                (O) x2, y2 <- TARGET CELL
  		// 
  		let dot = {};
  		// get an angle between two points
  		dot['angle'] = Math.atan2(y2 - y1, x2 - x1);
  		// calculet offset1 x,y, based on radius
	  	dot['x1'] = x1 + Math.cos(dot['angle'])*this.movingObj.radius;
			dot['y1'] = y1 + Math.sin(dot['angle'])*this.movingObj.radius;
			// calculet offset2 x,y, based on radius
	  	dot['x2'] = x2 - Math.cos(dot['angle'])*this.movingObj.radius/2;
			dot['y2'] = y2 - Math.sin(dot['angle'])*this.movingObj.radius/2;
			// calculate hypotenuse, pythagoras
			dot['x'] = dot['x2'] - dot['x1'];
			dot['y'] = dot['y2'] - dot['y1'];
			dot['hypo'] = Math.sqrt( Math.abs(dot['x']*dot['x']) + Math.abs(dot['y']*dot['y']) );
			// get a travel time based on the length of hypotenuse
			dot['hypo'] = dot['hypo']  / this.blobSpeed;

  		// init scale and matrix animation
		gsap.set( this.anime.nativeElement, { svgOrigin:"50 50", scale: this.movingObj.scale, attr:{ d: this.movingObj.path } });
		
		// set to offset1
		gsap.set(this.mover.nativeElement, { 
			svgOrigin:"50 50", 
			x: dot['x1'], 
			y: dot['y1'],
		});

		// move to offset2
		this.timeline = gsap.timeline({});
		this.timeline.to(this.mover.nativeElement, {
			svgOrigin:"50 50", 
			x: dot['x2'], 
			y: dot['y2'],
			duration: dot['hypo'], 
			ease: "none",
			callbackScope: this,
			onUpdate: this.hitDetection,			
			onComplete: this.callbackStatus,
			onCompleteParams: [this.movingObj]
		});

		// progress
		if ( progress ) {
			// when a user rotate a screen
			// a different set of coordinates are introduced
			// so we need a progress, to start where we finished
			this.timeline.progress( progress );
		}

		// are we in pause ?
		if (!this._service.allowTimeline) {
			this.timeline.pause();	
		}

	}

	hitDetection() {
		// *Angular v10
		// overlap detection in %
		const detected = '70%'; 
		const flip_detected = '50%';

		// detect HOLE if any exists
		if( (this.hole.length > 0) && (!this.hitHole)) {			
			for(let obj in this.hole) {
				if(Draggable.hitTest(this.anime.nativeElement, '#'+this.hole[obj], detected)){
					this.hitHole = true;
					// slow down by half
					// this.timeline.timeScale( 0.5 );
					this.blobSpeed = this.blobSpeed / 2;
					this.animateBlob( true , this.timeline.progress() );

					// animation				
					let hole = gsap.timeline();				
					hole.to(this.anime.nativeElement, { scale: this.movingObj.scale/1.5, opacity: 0.6, ease: "out", duration: 0.5},0)
					hole.to(this.anime.nativeElement, { scale: this.movingObj.scale, opacity: 1, ease: "elastic.out", duration: 3.5},0.5);
					hole.to(this.fx.nativeElement, { attr:{rx: '20%', ry: '20%'}, ease: "out", duration: 0.5},0);
					hole.to(this.fx.nativeElement, { attr:{rx: '5%', ry: '5%'}, ease: "in", duration: 2},0.5);
					console.log("+ HOLE hit by " + this.movingObj.whois + " / " + this.movingObj.id );
				}
			}
		}

		// detect FIELD if any exists
		if( (this.field.length > 0) && (!this.hitField)) {			
			for(let obj in this.field) {
				if(Draggable.hitTest(this.anime.nativeElement, '#'+this.field[obj] + ' .detect_hit', detected)){
					this.hitField = true;
					// remove half of the counter
					this.movingObj.num = Math.round( this.movingObj.num / 2 );
					// animation				
					let field = gsap.timeline();				
					field.to(this.anime.nativeElement, { scale: this.movingObj.scale/1.5, opacity: 0.6, ease: "out", duration: 1},0);				
					field.to(this.anime.nativeElement, { scale: this.movingObj.scale, opacity: 1, ease: "bounce.out", duration: 2}, 1);
					field.to(this.text.nativeElement, { text: { value: this.movingObj.num }, duration: 0 }, 0.5);
					field.to(this.fx.nativeElement, { attr:{rx: '20%', ry: '20%'}, ease: "out", duration: 1},0);
					field.to(this.fx.nativeElement, { attr:{rx: '5%', ry: '5%'}, ease: "in", duration: 1},1);
					this.shakeMyCell( this.text.nativeElement );
					console.log("+ FIELD hit by " + this.movingObj.whois + " / " + this.movingObj.id );
				}
			}
		}

		// PLAYER
		// detect FLIP PLAYER if any exists		
		if( (this.flip.length > 0) && (!this.hitFlip)) {			
			for(let obj in this.flip) {				
				if(Draggable.hitTest(this.anime.nativeElement, '#'+this.flip[obj] + ' .detect_hit_player', flip_detected)){
					this.hitFlip = true;
					// reward player
					if (this.movingObj.whois === 'player') { 
						this.movingObj.num = Math.round( this.movingObj.num * 1.5 );
					}
					// flip ai
					if (this.movingObj.whois === 'ai') {
						this.movingObj.whois = 'player';
					}	
					// animation				
					this.flipPlayerAI();
					console.log("+ FLIP PLAYER change cell to " + this.movingObj.whois + " / " + this.movingObj.id );		
					
				}
			}			
		}

		// AI
		// detect FLIP AI if any exists
		if( (this.flip.length > 0) && (!this.hitFlip)) {			
			for(let obj in this.flip) {				
				if(Draggable.hitTest(this.anime.nativeElement, '#'+this.flip[obj] + ' .detect_hit_ai', flip_detected)){
					this.hitFlip = true;
					// reward ai
					if (this.movingObj.whois === 'ai') { 
						this.movingObj.num = Math.round( this.movingObj.num * 1.5 );
					}
					// flip player
					if (this.movingObj.whois === 'player') {
						this.movingObj.whois = 'ai';
					}
					// animation				
					this.flipPlayerAI();	
					console.log("+ FLIP AI change cell to " + this.movingObj.whois + " / " + this.movingObj.id );
				}
			}			
		}

		// nothing here
		return;
	}

	flipPlayerAI() {
		// *Angular v10
		this._refresh.detectChanges();
		let flip = gsap.timeline();
		flip.to(this.anime.nativeElement, { scale: this.movingObj.scale/1.5, opacity: 0.6, ease: "out", duration: 0.5},0)
		flip.to(this.anime.nativeElement, { scale: this.movingObj.scale, opacity: 1, ease: "elastic.out", duration: 3.5},0.5);
		flip.to(this.text.nativeElement, {  svgOrigin:"50 50", rotate: 720, duration: 1 }, 0);
		flip.to(this.fx.nativeElement, { attr:{rx: '20%', ry: '20%'}, ease: "out", duration: 0.5},0);
		flip.to(this.fx.nativeElement, { attr:{rx: '5%', ry: '5%'}, ease: "in", duration: 2},0.5);	
	}

	shakeMyCell(obj){
		// *Angular v10
		// obj:String, DOM class name or ID
		let dur = 0.06;
		let shake = gsap.timeline();		
		shake.set(obj,{x:0});
		shake.set(obj,{x:3,delay:dur});
		shake.set(obj,{x:-2,delay:dur});
		shake.set(obj,{x:2,delay:dur});
		shake.set(obj,{x:-1,delay:dur});
		shake.set(obj,{x:1,delay:dur});
		shake.set(obj,{x:0,delay:dur});
	}

	refreshPosition() {
		// *Angular v10
		// refresh a size of the blob
		gsap.set(this.anime.nativeElement,{svgOrigin:"50 50", scale: this.movingObj.scale });
		// and refresh a position in X,Y
		this.animateBlob( true , this.timeline.progress());
	}

	updateSpeed(speed) {
		// *Angular v10
		// get a speed based on whois
		speed = Number( speed[this.movingObj.whois] );
		let currentSpeed = this.blobSpeed;

		// do we need to update speed ?
		if (speed === currentSpeed) { return; }

		this.blobSpeed = speed;
		// speed up or slow down with timeScale
		this.animateBlob( true , this.timeline.progress() );
	}
	
	callbackStatus(obj) {	
		// object arrived to a target
	  	// check for further actions
	  	// go back to scope component
	  	obj.scope.endedMoveToTarget(obj);
	}

	ngOnDestroy() {
		// clear the timeline
		this.timeline.clear();
		this.timeline.kill();
		this.timeline = null;
		// console.warn("DESTROY", this.constructor.name, this.movingObj.id);
	}

}
