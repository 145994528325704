<main >

	<button #mainButton class="service" (click)="openFullscreen()">start the game</button>


	<svg #mainSVG class="svg ai goo" height="100" width="100" viewBox="0 0 100 100" preserveAspectRatio="none" >
		<g class="body noselect">
			<path #mainSVGblob class="fill_color" fill="gray" stroke="gray" stroke-width="0" d="M100,50c0,13.6-6.36,24.11-15.19,33.13C75.71,92.42,63.92,100,49.87,100c-16.44-.65-28.81-6.93-37.12-16C-5.58,63.83-3.13,31.33,18.06,13.38,26.14,6.53,36.06.54,49.87,0,65.85,0,82,6.63,91.19,18.25,97.91,26.76,100,38.33,100,50Z"  />
			<path #mainSVGhedron class="cell_color" fill="gray" stroke="gray" stroke-width="2" d="M13.81,51.6c-.06-9.92,8.54-7.51,8.37-14.65C21.53,8.14,40,31,56.37,18c2.5-2,7.95-1.51,11.06-.06,8.1,4.52-1.44,17.73,9.76,19.36A7.49,7.49,0,0,1,83,42.39c2.5,9.1-4.77,10.24-5.77,17s7.09,10.87-1.5,20.91C69.4,87.69,63.11,79,52.19,81.47c-5.45,1.21-6,3.46-10.47,4.37-9,2.06-22.45-2.28-20.8-11.4C23.13,57.84,13.86,60.07,13.81,51.6Z"  />
		</g>
	</svg>

	<svg style="display: none;">
	  <defs>
	    <filter id="goo">
	      <feGaussianBlur in="SourceGraphic" stdDeviation="8" result="blur" />
			<feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 30 -5" result="goo" />      
	      <feBlend in="SourceGraphic" in2="goo" operator="atop" />
	    </filter>
	  </defs>
	</svg>


</main>