import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { SaveService } from './save.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
	// *Angular v10

	// auth keys
	private authDB = 'auth';
	private authTest = 'startrek';

	// app storage
	private appSettingsDB = 'appstat'; 
	public appSettings = {};

	// for storing levels data
	public progressStorage = null;	// progress, only finished levels
	private errorDB = 'errors'; // error local storage key, when something is not right
	private progressDB = 'levels-v1'; // current local storage name for a level progress
	private progressKey = 'level'; // key name for each level within progressStorage
	private progress = {}; // empty/default value for progressStorage

	// transactions
	// purchase stars
	// acquire goodies
	// use goodies
	private goodiesDB = 'goodies'; // local storage name for transactions 
	private goodiesVerify = { transactions: [] }; // template for verification
	public goodiesTransaction = {}; // 

	// all stars
	public lastKnownGoodStars = null;

	constructor( 
		public _data: DataService, public _save: SaveService 
		) { 
			console.log("LOAD",this.constructor.name);
			
			// ********************************
			// *** INIT ***********************
			// ********************************
			this.init();
		}

	// *******************************
	// ********* UPDATE LOGIC ********
	// *******************************
	// EVENTS
	// (a) init
	// (b) player won
	// (c) player lost
	// (d) acquire goodies
	// (e) purchase stars from store
	// (f) use goodies
	//
	// UPDATE 	on event
	// levels 	(a)(b)
	// stage 	(a)(b)
	// stars 	(a)(b)(d)(e)
	// goodies 	(a)(d)(f)
	// *******************************

	// *******************************
	// ********* DATA TO SAVE ********
	// *******************************
	// - level progress
	// - app settings
	// - acquire & use & purchase
	// *******************************

	// and now we begin...
	// ********************************
	// *** INIT for everything ********
	// ********************************
	init() {	
		// *Angular v10	
		// init this.progressStorage
		// retrive json from a storage
		this.initProgress();

		// now check for storage data
		// this.progressStorage - stored user's progress
		// this._data.gameLevels - all levels in a  game, updated with this.progressStorage
		let storage = this.progressStorage;
		// go through all levels from localStorage
		// and update playerData
		if (storage) {
			for(let s in storage) {				
				if(storage[s]) {					
					// remove "level" from key string
					// get status and stars		
					let level = s.replace(/[^0-9\.]+/g, '');
					let status = storage[s].s;
					let time = storage[s].t;
					this.updateSinglePlayersData( level, status, time );
				}				
			}
		} else {
			// storage is empty
			// no need to update anything
		}
		// now lets init Stages data
		// this._data.gameStages - stage data is for drawing level's pages
		this.initStages();

		// now let's init appData		
		// this._data.appProducts - app products (goodies) is for storing and showing goodies in cart
		// this.goodiesTransaction - transaction of all godies bought and used
		this.initAppProducts();

		// init stars
		// this.lastKnownGoodStars - calculated stars
		this.updateAllStars();

		// and we are ready...
		console.log("%c INIT: " + this.constructor.name, "background: #eee; color: #333;", "- Levels, Stages, AppProducts, Stars" );
	}

	initProgress() {
		// *Angular v10
		// retrive json from local storage
		console.log("%c INIT: " + this.constructor.name, "background: #eee; color: #333;", "- initProgress" );
		let retrieveJSON = this.loadData( this.progressDB );
		try {
			// try to parse json
			retrieveJSON = JSON.parse(retrieveJSON);		
		} catch(err) {
			// json has errors			
			// console.log("storage error")
			// save it in error storage as a string
			this.saveData( this.loadData( this.progressDB ), this.errorDB, false );
			// save it and set default values
			this.progressStorage = this.progress;
			this.saveData( this.progress, this.progressDB, true );
			return;			
		}
		if (!retrieveJSON) {
			// json is empty and has no errors
			// console.log("storage empty")
			this.progressStorage = this.progress;
			this.saveData( this.progress, this.progressDB, true );
			return;			
		}
		// json is fine
		// set it to progressStorage
		// save it to a local storage
		this.progressStorage = retrieveJSON;
		this.saveData( retrieveJSON, this.progressDB, true );
	}


	// ********************************
	// *** GET PROGRESS FORM LEVELS ***
	// ********************************
	getPlayersData() {
		// *Angular v10	
		// return a full array of gameLevels
		// and make sure a progress is up to date
		// console.log("%c progressStorage " , "background: #ccc", this.progressStorage);
		// @JSON

		// it seems a progress is up to date
		if (this.progressStorage) { return this._data.gameLevels; }

		// init a progress and players data
		// for the first time only
		this.init();

		// return gameLevels from data.service		
		return this._data.gameLevels;

	}

	getPlayerDataByIndex(level:number) {
		// *Angular v10
		// get a gameLevels by level number
		// we do not need to update level and progress here
		// @object
		return this._data.gameLevels[level];
	}

	updateLevelPlayerWon(level, status, time) {
		// *Angular v10
		// console.log("%c update level " , "background: #ccc", level, status, time);
		// this is called only when a player wins
		// at the end of the level

		// do we have to init storage & data ?
		if (!this.progressStorage) {
			this.init();	
		}
		
		// now lets check if level exists
		let key = String(this.progressKey + level);
		if(this.progressStorage[key]) {
			// check if a progress is a necessary
			if (time < this.progressStorage[key].t) {
				// compare the values
				// update the values
				this.updateSingleProgressData( key, status, time );
				this.updateSinglePlayersData( level, status, time );
			} else { 
				// don't do anything!
				// slower time 
			}
		} else {
			// new level finished
			// insert a new key
			this.updateSingleProgressData( key, status, time );
			this.updateSinglePlayersData( level, status, time );
		}

		// let's update the stages
		// to see if anything change
		this.updateStages();
		// let's update the stage stars
		// to see if there is any extra star
		this.updateAllStars();
	}

	updateSingleProgressData(key, status, time) {
		// *Angular v10
		// insert a new key
		// into localStorage
		this.progressStorage[key] = {};
		this.progressStorage[key].s = status;	
		this.progressStorage[key].t = time;
		// save it to local storage
		this.saveData( this.progressStorage, this.progressDB, true );
	}

	updateSinglePlayersData(level, status, time) {
		// *Angular v10
		// set a status and time
		// calculate stars		
		let stars = this.getStarsPlayersData(time, level);
		// set
		this._data.gameLevels[level].status = status;
		this._data.gameLevels[level].stars = stars;
		this._data.gameLevels[level].finished = time;
	}

	getStarsPlayersData(time, level) {
		// *Angular v10
		// return 3,2, or 1 star based on time spent while playing
		// example for target 100s
		// < 100 	= (3) ***
		// 100<>150	= (2) **
		// > 150	= (1) *
		// @number
		let goal = this._data.gameLevels[level].time;
		let stars = 1;
		if (time < goal) {
			stars = 3;
		} else if (time < goal*1.5) {
			stars = 2;
		} else {
			stars = 1;
		}
		return stars;	
	}

	compareBestScore(time, level) {
		// *Angular v10
		// compare if a current time is better than previously finished time
		// @boolean

		// do we have to init storage & data ?
		if (!this.progressStorage) {
			this.init();	
		}
		// compare the finished time with current time
		if (!this._data.gameLevels[level].finished) {
			// empty entry, return true
			return true;
		} else {
			// compare current time
			return time < this._data.gameLevels[level].finished ? true : false;	
		}			
	}


	// ********************************
	// ******** UPDATE STAGES *********
	// ********************************
	getStagesNames() {		
		// *Angular v10
		// return a stages names
		// @JSON
		return this._data.gameStages;
	}

	initStages() {
		// *Angular v10
		// this should be run only once, at the begining

		// just in case!!! do we have to init storage & data ?
		if (!this.progressStorage) {
			this.init();
		}
		// update Stages
		this.updateStages();
	}

	updateStages() {
		// *Angular v10	
		// just in case!!! do we have to init storage & data ?
		if (!this.progressStorage) {
			this.init();
		}
		function returnBetweenTwoNumbers(j=0,k=1) {
			// return all nubers between j and k 
			return Array
				.apply(null, Array((k - j) + 1))
				.map(function(_, n){ return n + j; }); 
		}

		// key: "allow" is for allowing to play in the current stage
		// key: "completed" is for adding additional stars when a stage is completed of all levels
		let allowPlay = true; // first time is allowed
		for(let i in this._data.gameStages) {
			let whichLevels = returnBetweenTwoNumbers( this._data.gameStages[i].start, this._data.gameStages[i].end);
			if (allowPlay) { 
				this._data.gameStages[i]['allow'] = true; 
			} else {
				this._data.gameStages[i]['allow'] = false; 	
			}
			this._data.gameStages[i]['completed'] = true;
			for(let j in whichLevels) {
				if(this._data.gameLevels[whichLevels[j]].status != 'done') {
					// one of the levels is not completed in this stage 
					this._data.gameStages[i]['completed']=false; 
					allowPlay=false; 
				}
			}
		}
		// console.table(this._data.gameStages);
	}

	// ********************************
	// ******** CALCULATE STARS *******
	// ********************************
	getAllStars() {
		// *Angular v10	
		// return stars
		// @number
		return this.lastKnownGoodStars;

	}

	updateAllStars() {
		// *Angular v10
		// this should be triggerd on: 
		// triggerd at init
		// triggerd at players win
		// triggerd at acquired goodies
		// triggerd at purchase

		// do we have to init storage & data ?
		if (!this.progressStorage) {
			this.init();	
		}

		// STARS = LEVELS + STAGES + PURCHASES - GOODIES

		// 1. ADD LEVELS
		// get all stars from finished levels
		let stars = 0;
		for (let i in this._data.gameLevels) {
			if (this._data.gameLevels[i]['stars']) {
				// get all stars
				stars = stars + (parseInt(this._data.gameLevels[i]['stars']))	
			}
		}
		// 2. ADD STAGES
		// get alls stars from finished screens
		for (let i in this._data.gameStages) {
			if ( this._data.gameStages[i].completed == true) {
				stars = stars + this._data.gameStages[i].extrastars;
			}
		}

		// 3. ADD PURCHASES
		// get purchased stars and add them to the rest of them
		for (let i in this.goodiesTransaction['transactions']) {
			if (this.goodiesTransaction['transactions'][i].type === 'purchase' ) {
				stars = stars + parseInt(this.goodiesTransaction['transactions'][i].stars);
			}
		}

		// 4. SUBSTRACT ACQUIRED GOODIES  
		// get acquired stars and remove them from stars
		for (let i in this.goodiesTransaction['transactions']) {
			if (this.goodiesTransaction['transactions'][i].type === 'acquire' ) {
				stars = stars - parseInt(this.goodiesTransaction['transactions'][i].stars);
			}
		}

		// update global variable
		this.lastKnownGoodStars = stars < 0 ? 0 : stars;
	}


	// ********************************
	// ******** GAME SETTINGS *********
	// ********************************
	getGameSettings() {
		// *Angular v10
		// return game setting
		// @object	
		return this._data.gameSettings;
	}
	restoreGameplay() {
		// *Angular v10
		// restore gameplay
		// *** restore deepClone *** object.gameplay 
		this._data.gameSettings.gameplay = JSON.parse(this._data.gameplayDefaults);
	}
	changeGameplay( gameplay ) {
		// *Angular v10
		// settings.gameplay.counters.player
		if (!gameplay) { return; }
		// there must be 2 levels in array
		// no exeptions
		for (var a in gameplay) {
			// first level is type of the gameplay like: counter, player, timer
			for(var b in gameplay[a]) {
				// lets change the value
				this._data.gameSettings.gameplay[a][b] = gameplay[a][b];
				console.log( ' +value added: ',b, gameplay[a][b]);
			}
		}
	}



	// ********************************
	// ********** APP SETTINGS ********
	// ********************************
	getAppSettings() {
		// *Angular v10*
		let defaultAppSettings = this._data.appSettings;
		// retrive json from local storage
		let retrieveJSON = this.loadData( this.appSettingsDB );
		try {
			// try to parse json			
			retrieveJSON = JSON.parse(retrieveJSON);	
		} catch(err) {
			// json has errors
			// save it and return default values
			this.appSettings = defaultAppSettings;
			this.saveData( this.appSettings, this.appSettingsDB );
			return this.appSettings;	
		}
		if (!retrieveJSON) {
			// json is empty and has no errors
			// return default
			this.appSettings = defaultAppSettings;
			this.saveData( this.appSettings, this.appSettingsDB );
			return this.appSettings;	
		}
		// json is fine
		// check if json has all keys, if not add default values and remove redundant
		retrieveJSON = this.verifyJsonTemplate(retrieveJSON, defaultAppSettings);
		this.appSettings = retrieveJSON;
		this.saveData( this.appSettings, this.appSettingsDB );
		// everything is ready
		return this.appSettings;
	}

	changeAppSettings(newSettings) {
		// *Angular v10*
		if(!newSettings) { return; }

		// check if json has all keys, if not add default values and remove redundant
		let defaultAppSettings = this._data.appSettings;		
		this.appSettings = this.verifyJsonTemplate(newSettings, defaultAppSettings);
		this.saveData( this.appSettings, this.appSettingsDB );
		// everything is ready
		return this.appSettings;

	}


	// ********************************
	// *********** GOODIES ************
	// ********************************
	getAppProducts () {
		// *Angular v10
		// get a game app products
		// @JSON
		return this._data.appProducts;
	}
	initAppProducts() {
		// *Angular v10*
		let defaultGoodies = this.goodiesVerify;
		// retrive json from local storage
		let retrieveJSON = this.loadData( this.goodiesDB );
		try {
			// try to parse json			
			retrieveJSON = JSON.parse(retrieveJSON);	
		} catch(err) {
			// json has errors
			// save it and return default values
			this.goodiesTransaction = defaultGoodies;
			this.saveData( this.goodiesTransaction, this.goodiesDB );
			return this.goodiesTransaction;	
		}
		if (!retrieveJSON) {
			// json is empty and has no errors
			// return default
			this.goodiesTransaction = defaultGoodies;
			this.saveData( this.goodiesTransaction, this.goodiesDB );
			return this.goodiesTransaction;	
		}
		// json is fine
		this.goodiesTransaction = retrieveJSON;
		retrieveJSON = this.verifyJsonTemplate(this.goodiesTransaction, this.goodiesVerify);
		this.saveData( this.goodiesTransaction, this.goodiesDB );
		
		this.updateAppProduct();
		// everything is ready

	}
	updateAppProduct() {
		// *Angular v10*		

		// go through app products names
		for (var app in this._data.appProducts) {			
			
			let keyname = this._data.appProducts[app].name;

			// go through all transactions
			// filter array in get length of transaction for specific name 'keyname'
			let acqindex 	= this.goodiesTransaction['transactions'].filter(item => item.type === 'acquire' && item.name === keyname).length;
			let useindex 	= this.goodiesTransaction['transactions'].filter(item => item.type === 'use' && item.name === keyname).length;
			let goodies 	= acqindex - useindex;

			if(acqindex) {
				// add 'aquire' index
				// how many did I acquire
				this._data.appProducts[app]['acquire'] = acqindex;	
			}
			if(useindex) {
				// add 'use' index
				// how many did I use
				this._data.appProducts[app]['use'] = useindex;	
			}
			/// check if there is any goodies to show
			if(goodies > 0) {
				// add 'goodies' index
				// how many I have
				this._data.appProducts[app]['goodies'] = goodies;	
			} else {
				// delete any trace of goodies
				delete this._data.appProducts[app]['goodies'];
			}
		}
	}

	acquireAppProduct (index) {
		// *Angular v10*
		if(!this._data.appProducts[index]) { return false; }

		// do I have enough stars
		if (this.lastKnownGoodStars < this._data.appProducts[index].stars) { return false; }

		this.lastKnownGoodStars = this.lastKnownGoodStars - this._data.appProducts[index].stars;

		// let be a goody!
		let goody = { 
			name: this._data.appProducts[index].name, 
			id: this._data.appProducts[index].id, 
			stars: this._data.appProducts[index].stars, 
			type: 'acquire'};

		// verify the right keys
		this.goodiesTransaction = this.verifyJsonTemplate(this.goodiesTransaction, this.goodiesVerify);

		// push it to array and save it
		this.goodiesTransaction['transactions'].push(goody);
		this.saveData( this.goodiesTransaction, this.goodiesDB );

		// update appProducts
		this.updateAppProduct();

		// everything seems to be OK
		return true;

	}

	useAppProduct (index) {
		// *Angular v10*
		if(!this._data.appProducts[index]) { return false; }
		if (this._data.appProducts[index].goodies < 1) { return false; }

		// let be a goody!
		let goody = { 
			name: this._data.appProducts[index].name, 
			id: this._data.appProducts[index].id, 
			stars: this._data.appProducts[index].stars, 
			type: 'use'};

		// verify the right keys
		this.goodiesTransaction = this.verifyJsonTemplate(this.goodiesTransaction, this.goodiesVerify);

		// push it to array and save it
		this.goodiesTransaction['transactions'].push(goody);
		this.saveData( this.goodiesTransaction, this.goodiesDB );

		// update appProducts
		this.updateAppProduct();

		// use app products
		this.changeGameplay( this._data.appProducts[index].gameplay );

		// everything seems to be OK
		return true;
	}


	// ********************************
	// ********* STORE ITEMS **********
	// ********************************
	getStoreItems () {
		// *Angular v10
		// get a game app products
		// @JSON
		return this._data.storeItems;
	}
	purchaseStars (index) {
		// *Angular v10
		if(!this._data.storeItems[index]) { return false; }
		if(!this._data.gameSettings.test.allowPurchase) { return false; }

		// update stars
		this.lastKnownGoodStars = this.lastKnownGoodStars + this._data.storeItems[index].stars;

		// let be a goody!
		let goody = { 
			name: 'str', 
			id: this._data.storeItems[index].id, 
			stars: this._data.storeItems[index].stars, 
			type: 'purchase'}

		// verify the right keys
		this.goodiesTransaction = this.verifyJsonTemplate(this.goodiesTransaction, this.goodiesVerify);

		// push it to array and save it
		this.goodiesTransaction['transactions'].push(goody);
		this.saveData( this.goodiesTransaction, this.goodiesDB );		

		return true;
	}


	// ********************************
	// ************ AUTH **************
	// ********************************
	authorization() {
		// *Angular v10*
		// is it a test
		if ( this.getGameSettings().test.testmode ) {
			return this.loadData(this.authDB) === this.authTest ? true : false;	
		} else {
			return true;	
		}
		return false;
	}
	saveAuthorization(key) {
		// *Angular v10*
		if (!key) { return; }
		this.saveData( key.toLowerCase() , this.authDB , false );
	}


	// ********************************
	// ************ TOOLS *************
	// ********************************
	verifyJsonTemplate(verify, template) {
		// *Angular v10*
		// check if "verify" has the same keys as "template"
		let verified = {};
		for(let j in template) {
			if(j in verify) { 
				// key is ok
				// console.log("exists "+ j ) 
				verified[j] = verify[j];
			} else {				
				// add a missing key
				// console.log("kein "+ j )
				verified[j] = template[j]; 	
			}
		}
		// return only verified keys
		verify = verified;
		return verify;
	}

	resetProgress() {
		// *Angular v10
		this.lastKnownGoodStars = 0;
		this.saveData( {} , this.progressDB, true );
	}
	resetTransaction() {
		// *Angular v10
		this.saveData( this.goodiesVerify , this.goodiesDB, true );
	}
	resetSettings() {
		// *Angular v10
		this.saveData( {}, this.appSettingsDB, true );
	}




	// ********************************
	// ********************************
	// L O A D ************************
	// ********************************
	// ********************************
	loadData(key) {
		// *Angular v10
		return this._save.loadData(key);
		// return window.localStorage.getItem( key );
	}
	// ********************************
	// ********************************
	// S A V E ************************
	// ********************************
	// ********************************
	saveData(value,key,json=true) {
		// *Angular v10
		this._save.saveData(value,key,json);
	}

}
