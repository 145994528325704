<figure #idObj class="{{coordX}}x{{coordY}} {{whois}} obj marg fields noselect hidden" id="{{id}}">	
	<svg class="abs svg noselect main-character" height="100" width="100" viewBox="0 0 100 100" preserveAspectRatio="none" >
		<g class="move noselect">
			<path #animeLite1 class="noselect fill_lite" stroke-width="1" stroke-linejoin="round" stroke-linecap="round" fill="none" d="M 48.4 48.5Q 48.35 45.7 47.45 42.45 45.55 35.9 41 33.6 37.35 31.75 35.85 31.85 34.75 31.9 34.15 33.35 33.15 35.65 32.6 36.35 31.15 38.15 28.1 38.95 23.3 40.2 20.55 36.35 17.9 32.6 20.45 29.85 22 28.15 20.45 25.7 18.8 23 20.2 20.6 21.7 17.6 20.55 16.6 19.35 15.5 15.4 17.1 11.7 18.6 10.45 16.3 9.4 14.3 10.5 11.7 11.25 9.9 10.2 8.7 9.75 8.15 8.2 7.3" />
			<path #animeLite2 class="noselect fill_lite" stroke-width="1" stroke-linejoin="round" stroke-linecap="round" fill="none" d="M 48.4 48.1Q 47.65 48.25 46.9 47.2 45.35 45.15 45.2 39.45 44.85 26.95 39.5 27.4 37.95 27.55 34.5 29.1 30.95 30.75 29.35 31 24.85 31.6 23.65 22.8 23.1 18.65 22.55 17.5 21.55 15.55 19.2 17 16.3 18.8 11.8 14.75 6.95 10.3 4.85 10.75" />
			<path #animeLite3 class="noselect fill_lite" stroke-width="1" stroke-linejoin="round" stroke-linecap="round" fill="none" d="M 48.2 48L 31.8 41.3 37.8 32.4 28 30.4 20 30.6 27.4 21.4 20.8 21.3 22.6 16.2 18.2 17.4 17.2 13.3 11.1 12 10.7 7 8.4 6.5" />
			<path #animeFX class="noselect fill_orbital" d="M50 50 L50 50" stroke-width="0" />			
			<path #animeBG class="noselect fill_color detect_hit" d="M50 50 L50 50" fill="gray" stroke="gray" stroke-width="0" />
		</g>
	</svg>
</figure>
