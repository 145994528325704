import { Component, Inject, ViewChild, ElementRef, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { gsap } from "gsap/all";
import { PlayerService } from './../services/player.service';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {

    private elem;
    // timeline
    private timeline;
    private ticker;
    private matrix;
    private matrixAll;

    @ViewChild('mainSVG') mainSVG: ElementRef;
    @ViewChild('mainSVGblob') mainSVGblob: ElementRef;
    @ViewChild('mainSVGhedron') mainSVGhedron: ElementRef;

    constructor(
        private _router: Router,
        public _service: PlayerService,
        @Inject(DOCUMENT) private document: any
    ) { }

  

    ngOnInit(): void {

      // timeline
      this.timeline = gsap.timeline({});
      this.ticker = gsap.timeline({});

      // get a DOM document
      this.elem = document.documentElement;
      // init
      console.log("%c INIT: " + this.constructor.name, "background: #eee; color: #333;");
    }

    ngAfterViewInit() { 
        this.startAnimation();
    }


	openFullscreen() {
        // *Angular v10
        if (this.elem.requestFullscreen) {
          this.elem.requestFullscreen();
        } else if (this.elem.mozRequestFullScreen) {
          /* Firefox */
          this.elem.mozRequestFullScreen();
        } else if (this.elem.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          this.elem.webkitRequestFullscreen();
        } else if (this.elem.msRequestFullscreen) {
          /* IE/Edge */
          this.elem.msRequestFullscreen();
        }

    // and go goto gotoHome
    this.gotoHome();
  }

  closeFullscreen() {
    // *Angular v10
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

    gotoHome() {
        // *Angular v10
        this._router.navigate(['/levels']);  
    }

    startAnimation() {
        // *Angular v10
        this.timeline =  gsap.timeline({repeat:-1, yoyo: true});
        this.timeline.to(this.mainSVG.nativeElement, { rotate: 360, duration: 10, ease: "power2.inOut"});

        let index = 0;
        this.ticker = gsap.timeline({repeat: -1, repeatDelay: 0, duration: 0.05, callbackScope: this, onRepeat: function() {

           let mx = this._service.settings.matrix.path[ index ];
           gsap.set( this.mainSVGhedron.nativeElement, { svgOrigin:"50 50", scale: 1.1, attr:{d:mx} } );
           if (index < this._service.settings.matrix.all) { 
               index++;
           } else { 
               index = 0;
           }
        }});

    }

    ngOnDestroy() {
        // *Angular v10

        // clear the timeline
        this.timeline.clear();
        this.timeline.kill();
        this.timeline = null;
        this.ticker.clear();
        this.ticker.kill();
        this.ticker = null;

        console.warn("DESTROY", this.constructor.name);
    }
}
