import { Component, Input, ViewChild, ElementRef, Renderer2, OnInit } from '@angular/core';
import { PlayerService } from './../../../services/player.service';

// gsap greensock tools
import { gsap, CSSRulePlugin, TextPlugin } from "gsap/all";
  gsap.registerPlugin(CSSRulePlugin);
  gsap.registerPlugin(TextPlugin);

@Component({
  selector: 'app-hole',
  templateUrl: './hole.component.html',
  styleUrls: ['./hole.component.scss']
})
export class HoleComponent implements OnInit {

  @Input() _data; // cell data 
  @Input() _created; // cell index

  private timeline;
  private odd:number = 1;
  index; id; coordX; coordY; whois; delay; counter; x; y; matrix; scaleFactor;

  @ViewChild('idObj') idObj: ElementRef; // main obj
  @ViewChild('animeBG') animeBG: ElementRef; // outer animation
  @ViewChild('animeFX') animeFX: ElementRef; // center animation
  @ViewChild('animeLite1') animeLite1: ElementRef; // center animation
  @ViewChild('animeLite2') animeLite2: ElementRef; // center animation
  @ViewChild('animeLite3') animeLite3: ElementRef; // center animation
  @ViewChild('animeLite4') animeLite4: ElementRef; // center animation
  @ViewChild('animeLite5') animeLite5: ElementRef; // center animation
  @ViewChild('animeLite6') animeLite6: ElementRef; // center animation
  @ViewChild('animeLite7') animeLite7: ElementRef; // center animation
  @ViewChild('animeLite8') animeLite8: ElementRef; // center animation
  @ViewChild('animeLite9') animeLite9: ElementRef; // center animation

    constructor(
      private _renderer: Renderer2,
      private _service: PlayerService
    ) { }

  ngOnInit(): void {
    // *Angular v10
    // created index
      this.index = this._created;
      this.delay = 0.2 + (this._created / 10);

      // data, from Grid component       
      this.id = 'cell' + this._created;
      this.whois = this._data.whois; 
      this.counter = this._data.counter;
      this.coordX = this._data.x; 
      this.coordY = this._data.y; 

      this.matrix = {};
      this.matrix.index = 1;
      this.matrix.speed = 0.2;
      this.matrix.path = this._service.settings.matrix.hole;
      this.matrix.all = this.matrix.path.length;

      this.timeline = gsap.timeline();

    console.log("%c INIT: " + this.constructor.name + " -id: "+this.id+" whois:"+this.whois+" ", "background: #eee; color: #333;");
  }

  ngAfterViewInit() {
    // *Angular v10  
    // player is visible in DOM
    // register a player to a global scope
    this._service.setPlayer(this.id, this);

    // make random position
    this.matrix.index = this._service.returnBetween(0,this.matrix.all,1);
    this.animatePath ();
  }

  refreshPosition() {
    // *Angular v10
    // set a left/top position into a grid
    // get a latest grid position
    // it could be resized/refreshed
    // translate x,y coordinate to left, top position
    let xy = this._service.getGridPositions( this.coordX, this.coordY );

    // check if there is any change
    if(this.x == xy[0] && this.y == xy[1]) { return; }  

    // set a new position
    this.x = xy[0];
    this.y = xy[1];
    //this._renderer.setStyle(this.idObj.nativeElement, 'left', this.x + 'px');
    //this._renderer.setStyle(this.idObj.nativeElement, 'top', this.y + 'px');
    gsap.set( this.idObj.nativeElement, { x: this.x, y:this.y } );

    // get a scale size factor for the player and mx array
    this.scaleFactor = this._service.settings.gridPosition.SVGscale;
    let mx = this.matrix.path[ Math.floor(this.matrix.index) ];
    gsap.set( this.animeBG.nativeElement, { svgOrigin:'50 50', attr:{d:mx}, scale:this.scaleFactor*1.2 } );
    gsap.set( this.animeFX.nativeElement, { svgOrigin:'50 50', attr:{d:mx}, scale:this.scaleFactor/1.5 } );

  }

  runComponent() {
    // *Angular v10    
    this.refreshPosition(); // refresh position

    // set a scale and fade    
    gsap.set(this.idObj.nativeElement,{scale:1, opacity:1});
    // set visibility  
    this._renderer.removeClass(this.idObj.nativeElement, this._service.settings.vars.hiddenClass );
    // show it to a stage, by animating it
    // gsap.to(this.idObj.nativeElement,{scale:1, opacity:1, delay: this.delay, duration:0.1});

    this.timeline = gsap.timeline({});
    this.animateThis();
    
  }

  updateCounter() {
    // *Angular v10
    this.animateLite();
    return;  
  }

  animateThis() {
    // *Angular v10
    // init cells with random position & rotation
    // get a random duration
    let dur = this._service.returnBetween(80,120,1);
    // create timeline for rotation
    this.timeline = gsap.timeline({
      onUpdate:this.animatePath, // update animation on ticker       
      onComplete:this.animateThis, // run again
      callbackScope:this //make sure the scope stays in this
    });

    /*
    // set back to 0
    this.timeline.set(this.idObj.nativeElement, { rotate: 0});
    // animate to 360 or -360
    let rot = this._service.returnBetween(-360,360,1);
    this.timeline.to(this.idObj.nativeElement, { duration: dur, rotate: rot },0);
    */

  }

  animatePath (){
    // *Angular v10  
    // reset index to zero
    // once reaches the end
    if (this.matrix.index >= this.matrix.all) {
      this.matrix.index = 0;
    }
    // animate from matrix
    let mx = this.matrix.path[ Math.floor(this.matrix.index) ];
    gsap.set( this.animeBG.nativeElement, { svgOrigin:'50 50', attr:{d:mx} } );
    this.matrix.index = this.matrix.index + this.matrix.speed;
  }

  animateLite(){

    /*
    let pos = [];
    let del = 0;
    const objs = [
      this.animeLite1.nativeElement,
      this.animeLite2.nativeElement,
      this.animeLite3.nativeElement,
      this.animeLite4.nativeElement,
      this.animeLite5.nativeElement,
      this.animeLite6.nativeElement,
      this.animeLite7.nativeElement,
      this.animeLite8.nativeElement,
      this.animeLite9.nativeElement,
    ];

    for (let i in objs) {
      del = this._service.returnBetween(0.4,0.9,0.1);
      // console.log(del);
      pos = this._service.returnCircumference(80);
      pos[0] = pos[0] + 50;
      pos[1] = pos[1] + 50;
      gsap.set( objs[i], { svgOrigin:'50 50', opacity: 0, attr:{cx: Math.floor(pos[0]), cy: Math.floor(pos[1])} } );
      gsap.to ( objs[i], { svgOrigin:'50 50', opacity: 0.4, attr:{cx: '50%', cy: '50%'}, duration: del } ); 
    }
    */

    /*
    gsap.set( this.animeBG.nativeElement, { svgOrigin:'50 50', opacity: 0, scale: 2} );
    gsap.to ( this.animeBG.nativeElement, { svgOrigin:'50 50', opacity: 1, scale: 1, duration: 0.4 } ); 
    gsap.to ( this.animeBG.nativeElement, { svgOrigin:'50 50', opacity: 0, scale: 0.1, delay:0.4, duration: 0.5 } ); 
    */

    if (this.odd === 1) {
      this.odd = 2;
      gsap.to ( this.animeBG.nativeElement, { svgOrigin:'50 50', scale: 1.2, ease: 'power1.inOut', duration: 0.9 } ); 


    } else {
       this.odd = 1;
       gsap.to ( this.animeBG.nativeElement, { svgOrigin:'50 50', scale: 0.8, ease: 'power1.inOut', duration: 0.9 } ); 
 
    }


    // console.log ( gsap.isTweening( this.animeLite1.nativeElement ) );
  }

  ngOnDestroy() {
    // clear the timeline
    this.timeline.clear();
    this.timeline.kill();
    this.timeline = null;
    console.warn("DESTROY", this.constructor.name);
  }

}
