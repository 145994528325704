import { Component, Input, ViewChild, ElementRef, Renderer2, OnInit } from '@angular/core';
import { PlayerService } from './../../../services/player.service';

// gsap greensock tools
import { gsap, CSSRulePlugin, TextPlugin } from "gsap/all";
  gsap.registerPlugin(CSSRulePlugin);
  gsap.registerPlugin(TextPlugin);

@Component({
  selector: 'app-flip',
  templateUrl: './flip.component.html',
  styleUrls: ['./flip.component.scss']
})
export class FlipComponent implements OnInit {

  @Input() _data; // cell data 
  @Input() _created; // cell index

  private timeline;
  index; id; coordX; coordY; whois; delay; counter; x; y; matrix; scaleFactor;

  @ViewChild('idObj') idObj: ElementRef; // main obj
  @ViewChild('animeBG') animeBG: ElementRef; // outer animation
  @ViewChild('animeBG1') animeBG1: ElementRef; // outer animation
  @ViewChild('animeBG2') animeBG2: ElementRef; // outer animation

    constructor(
      private _renderer: Renderer2,
      private _service: PlayerService
    ) { }

  ngOnInit(): void {
    // *Angular v10
    // created index
      this.index = this._created;
      this.delay = 0.2 + (this._created / 10);

      // data, from Grid component       
      this.id = 'cell' + this._created;
      this.whois = this._data.whois; 
      this.counter = this._data.counter;
      this.coordX = this._data.x; 
      this.coordY = this._data.y; 

      this.matrix = {};
      this.matrix.index = 1;
      this.matrix.speed = 0.3;
      this.matrix.path = this._service.settings.matrix.flip;
      this.matrix.all = this.matrix.path.length;

      this.timeline = gsap.timeline();

    console.log("%c INIT: " + this.constructor.name + " -id: "+this.id+" whois:"+this.whois+" ", "background: #eee; color: #333;");
  }

  ngAfterViewInit() {
    // *Angular v10  
    // player is visible in DOM
    // register a player to a global scope
    this._service.setPlayer(this.id, this);

    // make random position
    this.matrix.index = this._service.returnBetween(0,this.matrix.all,1);
    this.animatePath ();
  }

  refreshPosition() {
    // *Angular v10
    // set a left/top position into a grid
    // get a latest grid position
    // it could be resized/refreshed
    // translate x,y coordinate to left, top position
    let xy = this._service.getGridPositions( this.coordX, this.coordY );

    // check if there is any change
    if(this.x == xy[0] && this.y == xy[1]) { return; }  

    // set a new position
    this.x = xy[0];
    this.y = xy[1];
    //this._renderer.setStyle(this.idObj.nativeElement, 'left', this.x + 'px');
    //this._renderer.setStyle(this.idObj.nativeElement, 'top', this.y + 'px');
    gsap.set( this.idObj.nativeElement, { x: this.x, y:this.y, rotate: 0 } );

    // get a scale size factor for the player and mx array
    // this.scaleFactor = this._service.settings.gridPosition.SVGscale;
    // let mx = this.matrix.path[ Math.floor(this.matrix.index) ];
    //gsap.set( this.animeBG.nativeElement, { svgOrigin:'50 50', attr:{d:mx}, scale:this.scaleFactor*1.2 } );
    //gsap.set( this.animeFX.nativeElement, { svgOrigin:'50 50', attr:{d:mx}, scale:this.scaleFactor/1.5 } );

  }

  runComponent() {
    // *Angular v10    
    this.refreshPosition(); // refresh position

    // set a scale and fade    
    gsap.set(this.idObj.nativeElement,{scale:1, opacity:1});
    // set visibility  
    this._renderer.removeClass(this.idObj.nativeElement, this._service.settings.vars.hiddenClass );
    // show it to a stage, by animating it
    // gsap.to(this.idObj.nativeElement,{scale:1, opacity:1, delay: this.delay, duration:0.1});

    this.timeline = gsap.timeline({});
    this.animateThis();
    
  }

  updateCounter() {
    // *Angular v10
    // no need for this
    return;  
  }

  animateThis() {
    // *Angular v10
    // init cells with random position & rotation
    // get a random duration
    this.timeline = gsap.timeline({
      onUpdate:this.animatePath, // update animation on ticker       
      onComplete:this.animateThis, // run again
      callbackScope:this //make sure the scope stays in this
    });
   

    let dur = this._service.returnBetween(10,20,1);
    // create timeline for rotation
    // set back to 0
    this.timeline.set(this.idObj.nativeElement, { rotate: 0});
    // animate to 360 or -360
    let rot = 360;
    this.timeline.to(this.idObj.nativeElement, { duration: dur, ease: 'power1.inOut', rotate: rot },0);

  
  }

  animatePath (){
    // *Angular v10  
    // reset index to zero
    // once reaches the end
    if (this.matrix.index >= this.matrix.all) {
      this.matrix.index = 0;
    }
    // animate from matrix
    let mx = this.matrix.path[ Math.floor(this.matrix.index) ];
    gsap.set( this.animeBG.nativeElement, { svgOrigin:'50 50', attr:{d:mx} } );
    gsap.set( this.animeBG1.nativeElement, { svgOrigin:'50 50', attr:{d:mx} } );
    gsap.set( this.animeBG2.nativeElement, { svgOrigin:'50 50', attr:{d:mx} } );
    this.matrix.index = this.matrix.index + this.matrix.speed;
  }

  ngOnDestroy() {
    // clear the timeline
    this.timeline.clear();
    this.timeline.kill();
    this.timeline = null;
    console.warn("DESTROY", this.constructor.name);
  }

}
