import { Component, NgZone, ViewChild, ElementRef, Renderer2, OnInit} from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { PlayerService } from './../services/player.service';

import { gsap } from "gsap/all";

// import Swiper core and required components
import SwiperCore, { EffectCoverflow, Pagination, Swiper } from "swiper/core";
SwiperCore.use([EffectCoverflow,Pagination]);


@Component({
  selector: 'app-levels',
  templateUrl: './levels.component.html',
  styleUrls: ['./levels.component.scss']
})
export class LevelsComponent implements OnInit {

	private swiper;
	public slideID;

	// timeline
	private timeline;

	// needed for HTML draw
	public stages;
	public levels;

	@ViewChild('mainScreen') mainScreen: ElementRef;
	@ViewChild('mainGrid') mainGrid: ElementRef;

  	constructor(
  		private _route: ActivatedRoute,
  		private _router: Router,
  		public _service: PlayerService,
  		private _renderer: Renderer2,
  		private _zone: NgZone
  	) { }

  	ngOnInit(): void {

  		// *Angular v10
  		// get ID from URL if exists
  		/*
	    this.slideID = this._route.snapshot.paramMap.get('id');
	    this.slideID = this.slideID?parseInt(this.slideID):0; // change to a number
	    this.slideID = this.slideID?this.slideID:this._service.levelActiveIndex; // set 0 to nan
	    */
	    // return from last good position
	    this.slideID = this._service.levelActiveIndex;    

	    // get stages and levels from service 
	    this.levels = this._service.getPlayerData();
	    this.stages = this._service.getStagesNames();

	    // init
	    this.timeline = gsap.timeline({});
	    this._service.soundsHome();
	    console.log("%c INIT: " + this.constructor.name+" -slide "+this.slideID, "background: #eee; color: #333;");
	    //console.table(this.stages);
	    //console.table(this.levels);  

  	}

	ngAfterViewInit() {
		// *Angular v10
		// fade in mainScreen
	    gsap.set(this.mainScreen.nativeElement, { opacity: 0});
	    this._renderer.removeClass(this.mainScreen.nativeElement, this._service.settings.vars.hiddenClass )
	    gsap.to(this.mainScreen.nativeElement, { opacity: 1, delay: 0.5, duration: 0.5 });
  	}

	onSwiper(swiper) {
		// *Angular v10
		// init swiper
		this.swiper = swiper;
	}
	onSlideChange() {
		// *Angular v10
		// remember last slide
		if (!this.swiper) { return; }
		this._service.levelActiveIndex = this.swiper.activeIndex;
	}

	clickedLevel(level,allow) {
		// *Angular v10
		if(!level) { return; }		

		if (allow) {

			this._service.soundFX ( 'click' );
			// go to a game
			gsap.to(this.mainScreen.nativeElement, { opacity: 0, duration: 0.1, callbackScope:this, onComplete: function() {

				// runing functions in 3rd party libraries could be problamatic
				// let's keep them in angular zone
				this._zone.run(() => { this._router.navigate(['/game',level]) });

			}});
		} else {
			// NJET HARASHO
			this._service.soundFX ( 'notallowed' );
			// shake my screen
			this._service.shakeMyScreen( '.grid' );
			// add toast
			this._service.createToast( 'previous' );
		}
	}

	nextSlide() {
		// *Angular v10
		this.swiper.slideNext();
	}

	ngOnDestroy() {
		// *Angular v10
		// clear the timeline
		this.timeline.clear();
		this.timeline.kill();
		this.timeline = null;

		// destroy swiper
		this.swiper.destroy();		

		console.warn("DESTROY", this.constructor.name);
	}
  
}