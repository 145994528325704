<figure #idObj class="{{coordX}}x{{coordY}} {{whois}} obj marg fields noselect hidden" id="{{id}}">	
	<svg class="abs svg noselect main-character" height="100" width="100" viewBox="0 0 100 100" preserveAspectRatio="none" >
		<g class="move noselect">
			<path #animeBG1 class="noselect flip_left border_color " d="M50 50 L50 50" fill="gray" stroke="gray" stroke-width="0" style="clip-path: url(#leftFlip);" />
			<path #animeBG2 class="noselect flip_right border_color " d="M50 50 L50 50" fill="gray" stroke="gray" stroke-width="0" style="clip-path: url(#rightFlip);" />
			<path #animeBG class="noselect fill_color" d="M50 50 L50 50" fill="gray" stroke="gray" stroke-width="0"/>
		</g>
	</svg>
	<div class="abs detect_hit_player noselect"></div>
	<div class="abs detect_hit_ai noselect"></div>

</figure>
