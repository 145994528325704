import { Component, Input, ViewChild, ElementRef, Renderer2, ViewContainerRef, AfterViewInit, OnInit, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { PlayerService } from './../../services/player.service';
import { BlobComponent } from './blob/blob.component';

// gsap greensock tools
import { gsap, Draggable, CSSRulePlugin, TextPlugin } from "gsap/all";
	gsap.registerPlugin(Draggable);
	gsap.registerPlugin(CSSRulePlugin);
	gsap.registerPlugin(TextPlugin);

@Component({
  selector: 'app-cell',
  templateUrl: './cell.component.html',
  styleUrls: ['./cell.component.scss']
})
export class CellComponent implements OnInit {

	// input from Grid component html wrapper
	// *Angular v10
	@Input() _data; // cell data 
	@Input() _created; // cell index

	// counters		
	enemyCounter; friendCounter; bustCounter;
	// defaults
	index; id; coordX; coordY; endX; endY; whois; gameID; counter; delay;
	x; y; dragX; dragY;	centerDrag; scaleFactor; delayTrigger; fighting;
	// animation variables
	matrix; timeline; bustTimeline;

	// native elements selectors
	@ViewChild('idObj') idObj: ElementRef;
	@ViewChild('bodyCount') counterObj: ElementRef;
	@ViewChild('myCount') counterObjMy: ElementRef;
	@ViewChild('foeCount') counterObjFoe: ElementRef;
	@ViewChild('drag') dragObj: ElementRef;
	@ViewChild('draggingLine') dragLine: ElementRef;
	@ViewChild('dragMe') dragMe: ElementRef;
	@ViewChild('bustCount') bustCount: ElementRef;
	@ViewChild('addCount') addCount: ElementRef;
	@ViewChild('rollover') rollover: ElementRef;
	@ViewChild('orbit1') orbit1: ElementRef;
	@ViewChild('orbit2') orbit2: ElementRef;
	@ViewChild('orbit3') orbit3: ElementRef;
	@ViewChild('orbit4') orbit4: ElementRef;

	// animation matrix
	@ViewChild('anime') anime: ElementRef;
	@ViewChild('animeBG') animeBG: ElementRef;

	// [deprecated] container
	@ViewChild('moveContainer', {static: true, read: ViewContainerRef}) moveContainer: ViewContainerRef;

	constructor(
		private _renderer: 	Renderer2, 
		private _service: 	PlayerService, 
		private _resolver: 	ComponentFactoryResolver, 
		private _refresh: 	ChangeDetectorRef
	) { }

	ngOnInit(): void {

		// *Angular v10
		// created index
		this.index = this._created;
		this.delay = 0.2 + (this._created / 10);

		// data, from Grid component  		 
		this.id = 'cell' + this._created;
		this.whois = this._data.whois; 
		this.counter = this._data.counter;
		this.coordX = this._data.x; 
		this.coordY = this._data.y;  		

  		// set counters	
		this.enemyCounter = 0;
		this.friendCounter = 0;
		this.bustCounter = 0;
		this.delayTrigger = true; // delay a war for one second

		// player in war
		this.fighting = false;

		// set a center drag for mouse/touch
		this.centerDrag = true;

		// set matrix animation
		this.matrix = {};
		this.matrix.path = null;		
		this.matrix.all = null;
		this.matrix.index = 0;
		this.matrix.last = 0;
		this.matrix.speed = this._service.settings.vars.speedIdle;
		this.matrix.lerp = this._service.settings.vars.speedIdle;
		this.matrix.lerpLast = this._service.settings.vars.speedIdle;

		// timelines
		this.bustTimeline = gsap.timeline();
		this.timeline = gsap.timeline();

		console.log("%c INIT: " + this.constructor.name + " -id: "+this.id, "background: #eee; color: #333;");
	}

	ngAfterViewInit() {
		// *Angular v10	
		// player is visible in DOM
		// register a player to a global scope
		this._service.setPlayer(this.id, this);
	}

	ngOnDestroy() {
		// *Angular v10
		// clear the timeline
		this.timeline.clear();
		this.timeline.kill();
		this.timeline = null;
		this.bustTimeline.clear();
		this.bustTimeline.kill();
		this.bustTimeline = null;
		// remove a player from a service
		// just in case
	  this._service.removePlayer(this.id);
		console.warn("DESTROY", this.constructor.name);
	}

	refreshPosition() {
		// *Angular v10
		// set a left/top position into a grid
		// get a latest grid position
		// it could be resized/refreshed
		// translate x,y coordinate to left, top position
		let xy = this._service.getGridPositions( this.coordX, this.coordY );

		// check if there is any change
		if(this.x == xy[0] && this.y == xy[1]) { return; }	

		// set a new position
		this.x = xy[0];
		this.y = xy[1];
		//this._renderer.setStyle(this.idObj.nativeElement, 'left', this.x + 'px');
		//this._renderer.setStyle(this.idObj.nativeElement, 'top', this.y + 'px');
		gsap.set( this.idObj.nativeElement, { x: this.x, y:this.y } );

		// get a scale size factor for the player
		this.scaleFactor = this._service.settings.gridPosition.SVGscale;

		// scale of background 
		gsap.set( this.anime.nativeElement, { svgOrigin:'50 50', scale:this.scaleFactor } );
		gsap.set( this.animeBG.nativeElement, { svgOrigin:'50 50', scale:this.scaleFactor } );
		// scale of roll over fx
		gsap.set( this.rollover.nativeElement, { attr:{rx: this._service.settings.gridPosition.SVGroll, ry: this._service.settings.gridPosition.SVGroll}} );
		// drag animation
		gsap.set( this.dragMe.nativeElement, { svgOrigin:'50 50', scale: this._service.settings.gridPosition.SVGblob } );
		
	}

	runComponent() {
		// *Angular v10
		// **************************************************
		// this @methode is called from PARENT GAME component
		// go, go, go...
		// **************************************************
		// **************************************************		
		this.refreshPosition(); // refresh position
		this.addDrag(); // add a drag functionality
		this.whoAmI(); // check for a player/ai/empty
		this.showCounter(); // update counter

		// set a scale and fade		
		gsap.set(this.idObj.nativeElement,{scale:1, opacity:1});
		// set visibility	
		this._renderer.removeClass(this.idObj.nativeElement, this._service.settings.vars.hiddenClass );
		// show it to a stage, by animating it
		// gsap.to(this.idObj.nativeElement,{scale:1, opacity:1, delay: this.delay, duration:0.1});
		// start with animation
		this.animateSetParameters(); // animate MATRIX a component	

		this._refresh.detectChanges();
	}

	whoAmI() {
		// *Angular v10
		// determin who am I
		// change a playing class if it is necessary
		if(this.idObj.nativeElement.classList.contains( this.whois )){
			// it is the same class
			// remove only war class
			this._renderer.removeClass( this.idObj.nativeElement, this._service.settings.vars.warClass );
		} else {
			// remove all classes
			this._service.settings.vars.allClasses.forEach(el => 
				this._renderer.removeClass( this.idObj.nativeElement, el )
			);
			// set whois class
			this._renderer.addClass(this.idObj.nativeElement, this.whois);
		};


		// do you allow to drag?
		if (this._service.settings.gameplay.allowDrag[this.whois]) {
			// set it visible and allow drag
			this._renderer.removeClass(this.dragObj.nativeElement, this._service.settings.vars.hiddenClass );
			// do not enable if already enabled
			if( Draggable.get( this.dragObj.nativeElement ).enabled() ) 
				{ return };
			// enable drag
			Draggable.get( this.dragObj.nativeElement ).enable();
			
		} else {
			// hide and disable drag			
			Draggable.get( this.dragObj.nativeElement ).disable();
			this._renderer.addClass(this.dragObj.nativeElement, this._service.settings.vars.hiddenClass );

		}	
	}

	addDrag() {
		// *Angular v10
		// add a drag listener
		Draggable.create(this.dragObj.nativeElement, {
				callbackScope: this,
				edgeResistance:0.65,
		    type:'x,y',	
		   

		    onDragStart: function(drag) {
		    	this.dragX = 0; // cursor offset X
		    	this.dragY = 0; // cursor offset Y
					if (this.centerDrag) {
						// snap a dragging object
						// to a center of the cursor
						// use gsap for that and margin as css: left,top
						this.dragX = Math.floor(drag.layerX - this.x - this._service.settings.vars.margin);
						this.dragY = Math.floor(drag.layerY - this.y - this._service.settings.vars.margin);
						this.dragX = this.dragX < 100 ? this.dragX : 0;
						this.dragY = this.dragY < 100 ? this.dragY : 0;
						gsap.set( this.dragObj.nativeElement, {left:this.dragX, top: this.dragY});  	
					}
					// animation speed
		    	this.matrix.speed = this._service.settings.vars.speedMove;
		    	// set at top z-index
		    	this._renderer.setStyle(this.idObj.nativeElement, 'z-index', this._service.zIndex++);
		    	// line
		    	gsap.set( this.dragLine.nativeElement, { opacity: 0 } );
		    	gsap.to( this.dragLine.nativeElement, { opacity: 1, duration: 0.3 } );
		    },
		    onDragEnd: function(drag) {
		    	// validate a target
		    	// and reset a drag
		        this.clearDrag(drag);
		    },
		    onMove: function(drag) {
		    	// update all dragging element
		    	this.moveDrag(drag);
		    }

			/*
				callbackScope: this,
				minimumMovement: 25,
				liveSnap: null,
				
				edgeResistance:0.65,
		    type:"x,y",			    
		    onDragStart: function(drag) {
		    	// reset the starting point of dragging
		    	this.dragX = drag.layerX<100?drag.layerX:100; // bug fix	    	
		    	this.dragY = drag.layerY<100?drag.layerY:100; // bug fix
		    	if (this.centerDrag) {
		    		// snap a dragging object
		    		// to a center of the cursor
		    		// use gsap for that
		    		console.log("drag started", this.dragX, this.dragY, drag.x, drag.y);
		    		gsap.set( this.dragObj.nativeElement, {
		    			x:Math.floor(- this._service.settings.vars.margin + drag.layerX),
		    			y:Math.floor(- this._service.settings.vars.margin + drag.layerY)
		    		});	    	
		    	}
		    	// animation speed
		    	this.matrix.speed = this._service.settings.vars.speedMove;
		    	// set at top z-index
		    	this._renderer.setStyle(this.idObj.nativeElement, 'z-index', this._service.zIndex++);
		    	// line
		    	gsap.set( this.dragLine.nativeElement, { opacity: 0 } );
		    	gsap.to( this.dragLine.nativeElement, { opacity: 1, duration: 0.5 } );
		    },
		    onDragEnd: function(drag) {
		    	// validate a target
		    	// and reset a drag
		        this.clearDrag(drag);
		    },
		    onMove: function(drag) {
		    	// update all dragging element
		    	// this.moveDrag(drag);
		    }
		  */
		});
	}

	clearDrag(drag) {
		// *Angular v10
		// validate drag target
		this.validateDrag();
		// send drag back to a house		
		// and reset all positions
		this.dragX = 0;
		this.dragY = 0;
		// use gsap for x,y manipulation
		// gsap.set( this.dragObj.nativeElement, {left:0,top:0} );
		gsap.set( this.dragObj.nativeElement, {x:0,y:0,left:0,top:0} );
		gsap.to( this.dragMe.nativeElement, {x:0,y:0});
		gsap.to( this.dragLine.nativeElement, {attr:{d:"M50 50 L 50 50"}, duration:0.3, opacity: 0} );
		// reset speed back to normal
		this.matrix.speed = this._service.settings.vars.speedIdle;
	}

	moveDrag(drag) {
		// *Angular v10
		if (!drag) { return; }
		if (!this._service.allowTimeline) { return; }

		// animate dragging line and split element
		// calculate x,y
		// use gsap for animation
		let posX = Math.floor( gsap.getProperty(this.dragObj.nativeElement,'x') + this.dragX + this._service.settings.vars.margin );
		let posY = Math.floor( gsap.getProperty(this.dragObj.nativeElement,'y') + this.dragY + this._service.settings.vars.margin );		

		if (!posX || !posY) { return; } 

		

		// x,y = dragPosition - parentOffset + centerObject
		//let x = posX + (this.dragX - this._service.settings.vars.margin) // + this._service.settings.vars.margin;
		//let y = posY + (this.dragY - this._service.settings.vars.margin) // + this._service.settings.vars.margin;
		//let trueX = x - this.dragX;
		//let trueY = y - this.dragY;

		//let d = "M50 50 L" + (x) + " " + (y); // svg coordinates
		let d = "M50 50 L " + (posX) + " " + (posY); // svg coordinates
		//    (O) x1,y1 <- START POSITION
		//      \
		//      (X)  <- offset, blob position, dot['x1'], dot['y1']
		//        \
		//         \
		//          \
		//           \
		//            \
		//             (O) x2, y2 <- END DRAG
  		// 

		let x1 = 0;
		let y1 = 0;
		let x2 = posX - this._service.settings.vars.margin;
		let y2 = posY - this._service.settings.vars.margin;
		let rad = this._service.settings.gridPosition.SVGradius;
		let dot = {};
		dot['angle'] = Math.atan2(y2 - y1, x2 - x1);
		// calculet offset1 x,y, based on radius
  	dot['x1'] = x1 + Math.cos(dot['angle'])*rad;
		dot['y1'] = y1 + Math.sin(dot['angle'])*rad;

		// drag line
		gsap.set( this.dragLine.nativeElement, {attr:{d:d}} );
		// show split animation
		dot['x1'] = dot['x1'] < this._service.settings.gridPosition.SVGradius ? dot['x1'] : 0;
		dot['y1'] = dot['y1'] < this._service.settings.gridPosition.SVGradius ? dot['y1'] : 0;
		gsap.to(this.dragMe.nativeElement, {x: dot['x1'], y: dot['y1']});

		// check drop-ables elements for a hit
		// make sure the trashold is set as %
		// add a highlight style				
		let droppables = this._service.getDroppables() ;
		let overlapThreshold = this._service.settings.vars['trashold'];
		// go through all drop-ables and set/reset highlight class
		for(let i in droppables) {
			if (Draggable.get( this.dragObj.nativeElement ).hitTest( droppables[i].nativeElement, overlapThreshold )) {
				// hit is triggered
				this._renderer.addClass( droppables[i].nativeElement, this._service.settings.vars.highClass )
			} else {
				// no hit on elements
				this._renderer.removeClass( droppables[i].nativeElement, this._service.settings.vars.highClass )	
			}
		}
	}

	validateDrag() {
		// *Angular v10
		// check if there is any highlighted target
		// return id and element
		let getHighlighted = this._service.getHighlighted();
		if(!getHighlighted) { return; }	

		// get a target	
		let targetID = getHighlighted[0];

		// remove highlighted class
		this._renderer.removeClass( getHighlighted[1].nativeElement, this._service.settings.vars.highClass );

		// just to be sure if dragging is allowed
		// in case a dragging player is defeated
		if(!this._service.settings.gameplay.allowDrag[this.whois] ) { return; }

		// move to ID
		this.moveToID(targetID);
	}

	moveToID(targetID) {
		// *Angular v10
		// is there any target
		if(!targetID) { return; }

		// is animation even running
		if (!this._service.allowTimeline) { return; }

		// check if it is the same ID 
		if (targetID == this.id) { return; }

		// check for a valid target				
		let callbackScope = this._service.getPlayer(targetID);
		if (!callbackScope) { return; }	

		// check for a constructor name, must be the same
		if (callbackScope.constructor.name != this.constructor.name) { return; }

		// do not split if lower than 4
		if (this.counter < this._service.settings.gameplay.minsplit ) { return; }

		// detect tree
		this._refresh.detectChanges();

		// split the number and update the counter 
		// first number rounds up, second to a floor (in case of splitting 11: 5.5, 5.5 => 6, 5)
		let number = this._service.returnSplitNumber( this.counter / this._service.settings.vars.split );
		this.counter = number[0];
		this.showCounter();

		// set at top z-index
		this._renderer.setStyle(this.idObj.nativeElement, 'z-index', this._service.zIndex++);

		// get absolute coordinates in PX
		let start = this._service.getGridPositions(this.coordX, this.coordY);
		let end = this._service.getGridPositions(callbackScope.coordX, callbackScope.coordY);

		// save all settings to moving a object
		var id = this._service.returnRandomID('moving');
		// necessary properties
		this._service.moving[id] = {};
		this._service.moving[id].id = id;
		this._service.moving[id].num = number[1];
		this._service.moving[id].fromID = this.id;
		this._service.moving[id].targetID = targetID;
		this._service.moving[id].whois = this.whois;
		this._service.moving[id].scope = callbackScope;
		this._service.moving[id].timing = this._service.settings.gameplay.timing[this.whois];
		// service based
		this._service.moving[id].cells = 	this._service.cells;
		this._service.moving[id].timing = 	this._service.settings.gameplay.timing[this.whois];
		this._service.moving[id].path = 	this._service.settings.matrix.blob[this._service.returnBetween(1, this._service.settings.matrix.all, 1)];
		this._service.moving[id].radius = 	this._service.settings.gridPosition.SVGradius;
		this._service.moving[id].scale = 	this._service.settings.gridPosition.SVGblob;

		// console.log("MOVE to ", targetID)
		// !!! Important !!!
		// attach moving element to a target component
		callbackScope.animateMoveToTarget(this._service.moving[id]);	
	}

	animateMoveToTarget(movingObj) {
		// *Angular v10
		if (!movingObj) { return; }
		if (movingObj.targetID != this.id) { return; } // something is wrong

		// !!! Important !!!
		// this code is running only within TARGET object
		// moveToTarget method has been invoked within TARGET component constructor
		// ************************************************************************

		// [ deprecated ]
		// THE FOLLOWING SOLUTION DOESNT trigger DOM refresh quick enough
		// insert ng-template #template to ng-container #moveContainer
		// add a context as ID, for identification		
		// this.moveContainer.createEmbeddedView(this.template, {context: {id: movingObj.id, num: movingObj.num, whois: movingObj.whois}});
		// angular does not trigger changes quick enough
		// [ deprecated ]


		// BETTER SOLUTION
		// render BlobComponent into moveContainer
		// using a resolveComponentFactory
		// get a BlobComponent		
		const blobComponent = this._resolver.resolveComponentFactory( BlobComponent );
		// load a component to a container
		let componentRef =  this._service.movingArea.createComponent( blobComponent, 0 );
		//let componentRef =  this.moveContainer.createComponent( blobComponent, 0 ); // <- [deprecated]
		
		// add all variables
		componentRef.instance.movingObj = movingObj;
		// save an instance to a global scope
		this._service.moving[movingObj.id].componentRef = componentRef;

		// FORCE REFRESH to all components and children, by changing #location
		window.location.href = this._service.getURLforGame();	

		this._service.soundFX ( 'move_' + movingObj.whois  );	
		
	}

	endedMoveToTarget(movingObj) {
		// *Angular v10
		if (!movingObj) { return; }

		// destroy a BlobComponent
		// destroy triggers also component's ngOnDestroy
		this._service.moving[movingObj.id].componentRef.destroy();

		// delete the array
		delete this._service.moving[movingObj.id];

		// refresh DOM as soon as possible
		this._refresh.detectChanges();			

		// check the status if it is a friend or a foe
		this.checkStatus(movingObj);
	}

	checkStatus(movingObj) {
		// *Angular v10
		if (!movingObj) { return; }

		// moving object has arrived to a target
		// let's check if it is a friend, a foe or empty				
		if (this.whois === "empty") {
			// I'am EMPTY
			console.log("+ ADD to EMPTY " , this.id + " / number: " , movingObj.num);
			// add counter
			this.counter = this.counter + movingObj.num;
			this.whois = movingObj.whois;
			this.whoAmI(); // update who am I
			this.updateCounter(); // update counter
			this.animateBustCounter(this.counter, true); // animate bust tag

			// nothing to do
		}else if (this.whois === movingObj.whois ) {			
			// I'am a FRIEND
			console.log("+ ADD to a FRIEND " , this.id + " / number" , movingObj.num);
			// add counter
			// it will add a reciving number at the next ticker (updateCounter)	
			this.friendCounter = movingObj.num;
			this.animateBustCounter(this.friendCounter, true); // animate bust tag

		} else {
			// I am a FOE
			console.log("+ WAR with a FOE" , this.id + " / number" , movingObj.num);
			
			// set impact sound for the start of the battle
			if ( this.enemyCounter === 0) {
				this._service.soundFX ( 'war' );
			} 
			// it will add a reciving number at the next ticker (updateCounter)	
			this.enemyCounter = this.enemyCounter + movingObj.num;
			
		}

	}

	showCounter() {
		// *Angular v10
		// do not allow negative
		const min = this._service.settings.gameplay.counters.min;
		this.counter = this.counter<min?min:this.counter;		

		// do not exceed max
		const max = this._service.settings.gameplay.counters.max;
		this.counter = this.counter>max?max:this.counter;

		// show only Abs number
		// change it to a String first
		let val = String( (Math.round(this.counter)) );

		// use gsap for rendering
		// it is faster
		gsap.set( this.counterObj.nativeElement, {text:{value: val}} );

		// refresh a state of the component;
		// for every ticker (1s)
		this._refresh.detectChanges();	
	}

	updateCounter() {
		// *Angular v10
		// do not allow updating for "empty"
		if (this.whois === "empty") { return; }

		// check if I am in war
		if(!this.enemyCounter) {

			// I am NOT IN A WAR
			// *****************
			// *****************
			// *****************
			// add a friend counters
			// use Math.round to remove decimal points, which may come about durring a war
			this.counter = (this.counter + this.friendCounter + this.bustCounter + this._service.settings.gameplay.counters[this.whois]);
			// reset other counters once they are added
			this.bustCounter = 0;
			this.friendCounter = 0;
			this.delayTrigger = true;
			// I am not in war
			this.fighting = false;
			this.showCounter();
			return;

		} else {

			// I am IN A WAR
			// *************
			// *************
			// *************
			// 1. add a war class and animation speed
			this._renderer.addClass( this.idObj.nativeElement, this._service.settings.vars.warClass );
			this.matrix.speed = this._service.settings.vars.speedWar;

			// 2. add a friend counters
			this.counter = this.counter + this.friendCounter + this.bustCounter;
			// reset other counters once they are added
			this.bustCounter = 0;
			this.friendCounter = 0;
			// I am in war
			this.fighting = true;

			// 3. check if there is a main counter with less than 6
			const minsplit = Number(this._service.settings.gameplay.counters.minsplit);
			if ( this.counter<=minsplit || this.enemyCounter<=minsplit ) {
				// war ended, check who won
				this.didIwin();
				return;
			}

			// 4. check who is loosing
			if (!this.delayTrigger) {
				if (this.enemyCounter < this.counter) {
					// attacker is loosing
					var split = this.enemyCounter / this._service.settings.vars.warSplit;
					this.counter = this.counter - split;
					this.enemyCounter = this.enemyCounter - split;
				} else {
					// attacker is winning
					var split = this.counter / this._service.settings.vars.warSplit;
					this.counter = this.counter - split;
					this.enemyCounter = this.enemyCounter - split;
				}
			}			

			// 4. show the score			
			// make sure all vals are String
			let my = String(Math.round(this.counter));
			let foe = String(Math.round(this.enemyCounter));
			// use gsap for rendering
			gsap.set( this.counterObjMy.nativeElement, {text:{value: my}} );
			gsap.set( this.counterObjFoe.nativeElement, {text:{value: foe}} );

			this.animateBustCounter(split, false); // animate bust tag

			this.delayTrigger = false;
		}	
	}


	didIwin() {
		// *Angular v10
		// check who is a winner and who is a looser
		if (this.counter >= this.enemyCounter) {			
			// I WON
			// add to a bust counter the remainig of the enemy counter
			this.animateBustCounter(this.enemyCounter , true); // animate bust tag
			this.bustCounter = this.enemyCounter;
			this.enemyCounter = 0;
		} else {
			// I LOSE
			// add to a bust counter the remainig of the counter
			this.animateBustCounter(this.counter , true); // animate bust tag
			this.bustCounter = this.enemyCounter;
			this.enemyCounter = 0;

			// loser must be inverted
			// invert player to ai and vice versa
			this.whois = this._service.getInvertedPlayer(this.whois);
			console.log("+ NEW me is: ", this.whois);
		}

		// remove decimals on ALL counters
		// let's make it clean for next round
		// rounding could potentialy miss 1 point 
		this.counter = Math.round(this.counter);
		this.enemyCounter = Math.round(this.enemyCounter);
		this.friendCounter = Math.round(this.friendCounter);
		this.bustCounter = Math.round(this.bustCounter);

		// reset speed back to normal
		this.matrix.speed = this._service.settings.vars.speedIdle;

		// update everything		
		this.whoAmI();
		this.updateCounter();
	}



	// animate bust counter
	animateBustCounter(num , positive) {
		// *Angular v10
		if(!num) { return; }
		let val = (positive?"+":"-") + String(Math.round(num));
		let margin = 15 - (this.scaleFactor * 20);
		this.bustTimeline.clear();
		this.bustTimeline = gsap.timeline();
		this.bustTimeline.set( this.addCount.nativeElement, {svgOrigin:'50 50', opacity: 1, text:{value: val}, attr:{y:'20'}} );
		this.bustTimeline.to ( this.addCount.nativeElement, {ease: 'back.out(4)', duration: 1, attr:{y: margin}}, 0);
		this.bustTimeline.to ( this.addCount.nativeElement, {opacity:0, duration: 0.5}, 0.5);
	}











	// animate SVG
	animateSetParameters() {
		// *Angular v10
		// **************************************************		
		// get some random values for the first time		
		let inx = this._service.returnBetween( this.matrix.index, this._service.settings.matrix.all, 1 );
		// get index to a random position
		this.matrix.index = inx;

		// create animated PATH element
		let mx 		= this._service.settings.matrix.path[ this.matrix.index ];
		// let bg 		= this._service.settings.matrix.bg;
		let bg 		= this._service.settings.matrix.blob[ this.matrix.index ];
		// animate with gsap main animation
		gsap.set( this.anime.nativeElement, {svgOrigin:'50 50', attr:{d:mx}} );
		// animate with gsap background animation
		gsap.set( this.animeBG.nativeElement, {svgOrigin:'50 50', attr:{d:bg}} );

		// start animation
		this.animateCicle();
		
	}

	animateCicle() {
		// *Angular v10
		// init cells with random position & rotation
		// get a random duration
		let dur = this._service.returnBetween(30,50,1);
		// create timeline for rotation
		this.timeline = gsap.timeline({
			onUpdate:this.animateSVGPath, // update animation on ticker 			
			onComplete:this.animateCicle, // run again
			callbackScope:this //make sure the scope stays in this
		});

		// set back to 0
		this.timeline.set(this.anime.nativeElement, { rotate: 0});
		// animate to 360 or -360
		let rot = (Math.random()*10)>5?360:-360;
		this.timeline.to(this.anime.nativeElement, { duration: dur, rotate: rot },0);
		
		// animate orbit bg
		/*
		this.animateOrbit(this.orbit1.nativeElement, dur);
		this.animateOrbit(this.orbit2.nativeElement, dur/1.5);
		this.animateOrbit(this.orbit3.nativeElement, dur/2);
		this.animateOrbit(this.orbit4.nativeElement, dur/2.5);
		*/
		// this.timeline.pause(); // for the first time?
	}

	animateOrbit(nativeEl, duration ) {
		// *Angular v10	
		// just an animation
		let randomXY = this._service.getRandomCircumference( this._service.settings.gridPosition.SVGradius - 15 );
		let x = randomXY[0];
		let y = randomXY[1];
		x = Math.floor(50 + x);
		y = Math.floor(50 + y);
		this.timeline.set (nativeEl, { attr:{cx: '50%', cy: '50%'}});
		this.timeline.to  (nativeEl, { duration: duration/2, ease: 'power2.inOut', attr:{cx: x, cy: y}}, 0);
		this.timeline.to  (nativeEl, { duration: duration/2, ease: 'power2.inOut', attr:{cx: '50%', cy: '50%'}},duration/2);
	}


	animateSVGPath() {
		// *Angular v10	
		// reset index to zero
		// once reaches the end
		if (this.matrix.index >= this._service.settings.matrix.all) {
			this.matrix.index = 0;
		}

		// check if animation is necessary
		let mx 		= this._service.settings.matrix.path[ Math.floor(this.matrix.index) ];
		if (mx != this.matrix.last) {
			// animate only if there is any changes
			// animate with gsap main animation
			gsap.set( this.anime.nativeElement, { svgOrigin:'50 50', attr:{d:mx} } );
			this.matrix.last = mx;
		}

		// lerp it!
		// it is interpolation between two values, by percentage (1 is 100%)
		// each step closer is more slower
		// but first, check for changes
		if (this.matrix.lerp != this.matrix.speed) {
			this.matrix.lerp = (this.updateLerp( this.matrix.lerp, this.matrix.speed, 0.05));
		}

		// go to animation index by speed (larp) value
		// we are larping abs number, to get rid of decimals
		// therfore, it must be devided by 100
		this.matrix.index = this.matrix.index + (this.matrix.lerp/100);

	}

	updateLerp (x: number, y: number, a: number) {
		// *Angular v10
		// return iterpolated between "x" and "y", with number "a" as speed factor
		let ret = Number( x * (1 - a) + y * a );
		if (Math.ceil(ret) == y ) { return y; } // to close, return
		if (Math.floor(ret) == y) { return y; } // to close, return
		return ret;
	}




}
