import { Component, ElementRef, OnInit, Renderer2, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PlayerService } from './../services/player.service';
import { gsap, CSSRulePlugin } from "gsap/all";
gsap.registerPlugin(CSSRulePlugin);

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	@Input() _data;
	@Input() _type;

	public levelID = 1;
	public paused = false;
	private timeline = gsap.timeline();
	public allStars = 0;


	@ViewChild('pauseButton') pauseButton: ElementRef;
	@ViewChild('useButton') useButton: ElementRef;

  constructor(
  		private _router: Router,
		public _service: PlayerService,
		private _renderer: Renderer2, 
		) { }

  ngOnInit() {
		// *Angular v10
	   this.timeline.clear();

	   // get all stars value
	   this.allStars = this._service.getAllStars();

	   // register header to global player.service scope
	   this._service.header = this;

	   // init
	   console.log("%c INIT: " + this.constructor.name, "background: #eee; color: #333;");
	}

	ngAfterViewInit() {

	}


	toggleTicker() {
		// *Angular v10
		if (!this._service.ticker.paused()) {
			this._service.pauseTicker();
		} else {
			this._service.playTicker();
		}		
	}

	rotatePauseButton(rotating) {
		// *Angular v10
		if (rotating) {
			// game is on pause
			this.timeline.clear();
			gsap.set(this.pauseButton.nativeElement, { rotate: 0 });
			this.timeline = gsap.timeline({ repeat: -1});
			this.timeline.to(this.pauseButton.nativeElement, { rotate: 360, ease: 'linear', duration: 2});
		}	else {
			// go back to normal
			this.timeline.clear();
			gsap.set(this.pauseButton.nativeElement, { rotate: 0 });	
		}
	}

	toggleMusic( playing ) {	
		// *Angular v10
		// toggle mute
		this._service.soundFX ( 'click' );
		this._service._sounds.toggleGameSounds( playing );
		/*
		if ( playing ) {
			this._renderer.removeClass( this.muteMusic.nativeElement, 'hidden' );
			this._renderer.addClass( this.unmuteMusic.nativeElement, 'hidden' );
		} else {
			this._renderer.addClass( this.muteMusic.nativeElement, 'hidden' );
			this._renderer.removeClass( this.unmuteMusic.nativeElement, 'hidden' );	
		}
		*/
	}


	//*************************	
	gotoHome() {
		// *Angular v10
		this._service.soundFX ( 'click' );
		this._router.navigate(['/levels']);	
	}
	gotoSettings() {
		// *Angular v10
		this._service.soundFX ( 'click' );
		this._router.navigate(['/settings']);	
	}
	gotoCart() {
		// *Angular v10
		this._service.soundFX ( 'click' );
		this._router.navigate(['/cart']);	
	}

	useGoodie() {
		// *Angular v10
		
		// check if there is any goodies at all
		let goodies = 0;
		for(let i in this._service.appProducts) {
			if( this._service.appProducts[i].goodies ) { goodies++; } 
		}
		if (goodies) {
			// I have goodies, good for me!}
			let obj = {};
			obj['type'] = 'usegoodies';
			// open popup
			this._service.popupCreate(obj);
		} else {
			// Bljat! I don't heve any at all.
			this._service.createToast('use_denied');
		}
	}

	showGoodies(index) {
		// *Angular v10
		index = '#goodies_icon .' + index;
		gsap.set(index, {duration: 2, css: { display: 'block', scale: -0.5}});
		gsap.to(index, {scale: 1, duration: 4, ease: 'elastic.out(1,0.3)'});
	}

	ngOnDestroy() {
		// *Angular v10

		// clear the timeline
		this.timeline.clear();
		this.timeline.kill();
		this.timeline = null;

		this._service.header = null;
		console.warn("DESTROY", this.constructor.name);
	}

}
