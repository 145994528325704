<!-- game header -->
<ng-container *ngIf="_type=='gameHeader'">
	<header class="grid-2type">
		<nav class="left">
			<button #homeButton (click)="gotoHome()"><i class="icon-home small"></i></button>

			<ng-container *ngIf="this._service.appSettings.fx == true || this._service.appSettings.music == true ">
				<button *ngIf="this._service._sounds.allowSoundsInGame==true" 
					(click)="toggleMusic(false)" class=""><i class="icon-volume-up"></i>
				</button>
				<button *ngIf="this._service._sounds.allowSoundsInGame==false" 
					(click)="toggleMusic(true)" class=""><i class="icon-volume-mute"></i>
				</button>
			</ng-container>

			<button #pauseButton (click)="toggleTicker()"><i class="icon-media-pause"></i></button>	
			<button #useButton (click)="useGoodie()">
				<i class="icon-heart"></i>
				<span *ngIf="this._service.allGoodies > 0" class="mark">{{this._service.allGoodies}}</span>
			</button>	
			<h2 class="noselect"><span id="trigerCounter">000</span><small>s</small></h2>
		</nav>
		<nav class="right">
			<h3 class="noselect">
				Level {{_data.index}}<br>
				<small>{{_data.time}}s <i class="icon-three-stars three"></i></small>
			</h3>
		</nav>
		<nav id="goodies_icon" class="vertical">
			<figure class="goodies spd"></figure>
			<figure class="goodies dbl"></figure>
			<figure class="goodies slw"></figure>
			<figure class="goodies god"></figure>
		</nav>
	</header>
</ng-container>


<!-- main header -->
<ng-container *ngIf="_type=='mainHeader'">
	<header class="grid-3type">
		<nav class="left">

			<button #homeButton (click)="gotoHome()"><i class="icon-home small"></i></button>

			<ng-container *ngIf="this._service.appSettings.fx == true || this._service.appSettings.music == true ">
				<button *ngIf="this._service._sounds.allowSoundsInGame==true" 
					(click)="toggleMusic(false)" class=""><i class="icon-volume-up"></i>
				</button>
				<button *ngIf="this._service._sounds.allowSoundsInGame==false" 
					(click)="toggleMusic(true)" class=""><i class="icon-volume-mute"></i>
				</button>
			</ng-container>

		</nav>
		<nav class="right">
			<h2 class="noselect starholder"><span id="allStars" class="fx-star">{{this._service.allStars}}</span><i class="icon-star inline"></i></h2>
			<button #homeButton (click)="gotoCart()"><i class="icon-cart"></i></button>
			<button #homeButton (click)="gotoSettings()"><i class="icon-cog large"></i></button>
		</nav>
	</header>
</ng-container>