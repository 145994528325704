
import { Component, ViewChild, ElementRef, ViewContainerRef, Renderer2, OnInit  } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { gsap } from "gsap/all";
import { PlayerService } from './../services/player.service';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit {

	// *Angular v10
	// variables
	public headerType;
	public playerDataLevel;
  public playerDataPositions;

	private gameID; // game ID - level number
	private stageNumber; // stage number
	private w; private h; // window dimension
	private gridOrientation; // stage orientation
	private gridX; private gridY; // grid points (count) based on orientation
	private rectWidth; private rectHeight; // grid rectangle
	private stageWidth; private stageHeight; // calculated width/height
	private lastStageWidth; private lastStageHeight; // previous calculated width/height
	private dotsArray=[]; // for drawing test dots
	private hasRefreshed = false;

	// get elements
	@ViewChild('stage') stage: ElementRef;
	@ViewChild('grid') gridObj: ElementRef;
	@ViewChild('dots') dots: ElementRef;
	@ViewChild('movingArea', {static: true, read: ViewContainerRef}) movingArea: ViewContainerRef;

  	constructor( 
		private _route: ActivatedRoute,
		private _renderer: Renderer2, 
		public _service: PlayerService
	) { }

	ngOnInit(): void {
		// *Angular v10
		// get an ID
	    // subscribe to "/game" location, to retrive an ID
	    let testLevel = null;
	    let testJson = null;
	    let allowlevel = null;
	    let getUser = null;

	  // get URL params, for testing levels
		// /game?decode=base64&json=DGEVKSOF4OP...
		this._route.queryParams.subscribe(params => {
		  	
		    testLevel = params.decode;
		    getUser = params.user;
		    if(testLevel) {
			    try {
			    	testJson = atob( params.json );
			    	testJson = JSON.parse( testJson );
			    } catch(e) {
			    	console.warn('!!! Cannot parse json from URL !!!');
			    	console.warn('!!! Error:', params.json);
			    	testLevel = false; 	
			    }	
		    }	    
		});

		// get param map :id
		// /game/1
		this._route.paramMap.subscribe((params: ParamMap) => {			
			allowlevel = Number(params.get('id'));
		});

		if (allowlevel && this._service.levelAllowed(allowlevel)) {
			// level is allowed ta access
			// get all information for a current level 
			this.gameID = allowlevel;
	    this._service.gameID = this.gameID;
	    this.playerDataLevel = this._service.getPlayerDataByIndex(this.gameID);
	    this.playerDataPositions = this.playerDataLevel.positions;
	    this._service.AILevel = this.playerDataLevel.ai;
	    this.stageNumber = Number (this._service.getStageFromLevelNumber ( this.gameID )) + 1; 

		} else if (testLevel === 'base64') {
			// url has a testing json
			console.warn( "Level Json loaded:",testJson )
			this.gameID = testJson.index?testJson.index:1;
	    this._service.gameID = this.gameID;
	    this.playerDataLevel = testJson;
	    this.playerDataPositions = testJson.positions?testJson.positions:{};
	    this._service.AILevel = testJson.ai?testJson.ai:1;
	    this.stageNumber = Number (this._service.getStageFromLevelNumber ( this.gameID )) + 1; 

		} else {
			// something is wrong, go to level 1
			console.warn('!!! Default level 1 loaded');
			this.defaultLevel(); 
			this.stageNumber = 1; 	
		}
		this._service.soundsStage( this.stageNumber );
	  console.log("%c INIT: " + this.constructor.name + " -level: " + this.gameID, "background: #eee; color: #333;");
	}

	defaultLevel() {
		// *Angular v10
		// you don't have any access
		// or something went wrong
		this._service.createToast('nolevel');
		this.gameID = 1;
	  this._service.gameID = this.gameID;
	  this.playerDataLevel = this._service.getPlayerDataByIndex(this.gameID);
		this.playerDataPositions = this.playerDataLevel.positions;
		this._service.AILevel = this.playerDataLevel.ai;
	}

	ngOnDestroy() {
		// *Angular v10
	    // stop a game
	    this._service.eventStopGame();
	    console.warn("DESTROY", this.constructor.name);
	}

  	ngAfterViewInit() {
  		// *Angular v10
  		// grid is visible in DOM
	    // use gsap for animate
	    // fade in & from top
	    gsap.set(this.gridObj.nativeElement, { opacity: 0});
	    this._renderer.removeClass(this.gridObj.nativeElement, this._service.settings.vars.hiddenClass )
	    gsap.to(this.gridObj.nativeElement, { opacity: 1, delay: 0.1 });

	    // register movingArea
	    this._service.movingArea = this.movingArea;

	    // ************************************
	    this.calculate(); // calculate a grid
	    // start a game
	    this._service.eventStartGame(); // init all cells
  	}

    calculate() {
    	// *Angular v10
    	// calculate window size
    	// mesure stage's width and height  
	    this.w = Math.round( document.body.getBoundingClientRect().width );
	    this.h = Math.round( document.body.getBoundingClientRect().height );

	    /* this is not really necessary, but it doesnt hurt */
	    if ( (this.h < 300 || this.w < 300 ) && !this.hasRefreshed ) {
	    	console.warn("Dimension error : ", "width:", this.w , "height:", this.h);
		    this.w = this.w>300?this.w : Math.round( this.stage.nativeElement.offsetWidth ); 
		    this.h = this.h>300?this.h : Math.round( this.stage.nativeElement.offsetHeight );
		    this.w = this.w>300?this.w : 320; 
		    this.h = this.h>300?this.h : 320;
	    	console.warn("try to correct  : ", "width:", this.w , "height:", this.h);
	    	console.warn("refreshing...");
	    	setTimeout(()=>{ this.onResize(); }, 100);
	    }
    

	    // GRID
	    // determin if it is better portrait or landscape
	    // for "landscape" a height must be less than 620
	    if ( (this.w > this.h) && (this.h < this._service.settings.grids.forceLandscape) ) {
	      this.gridOrientation = 'landscape';      
	    } else {
	      this.gridOrientation = 'portrait';
	    }

		// landscape vs portrait
		// retrive a proper coordinates from settings based on orientation
		this.gridX = this._service.settings.grids[ this.gridOrientation ].gridX;
		this.gridY = this._service.settings.grids[ this.gridOrientation ].gridY;

	    // calculate grid rectangles
	    this.rectWidth = Math.floor( this._service.returnBetweenMixMax( this._service.settings.grids.min,this._service.settings.grids.max,( this.w/this.gridX ) ) );
	    this.rectHeight = Math.floor( this._service.returnBetweenMixMax( this._service.settings.grids.min,this._service.settings.grids.max,( this.h/this.gridY ) ) );

		// calculate the stage width and height based on rectangles
		this.stageWidth =  this.rectWidth * this.gridX - this._service.settings.grids.margin;
		this.stageHeight = this.rectHeight * this.gridY - this._service.settings.grids.margin;

	    // do we need to draw/update a grid and dots?
	    // well, we need it for the first time,
	    // but not for every window refresh!
	    if (this.lastStageWidth != this.stageWidth || this.lastStageHeight != this.stageHeight) {

			// remember, for the next comparison
			this.lastStageWidth = this.stageWidth;
			this.lastStageHeight = this.stageHeight;

      		// save grid positions to a settings, for later usege/comparison
			this._service.settings.gridPosition.orientation = this.gridOrientation;
			this._service.settings.gridPosition.rectWidth = this.rectWidth;
			this._service.settings.gridPosition.rectHeight = this.rectHeight;
			this._service.settings.gridPosition.stageW = this.stageWidth; 
			this._service.settings.gridPosition.stageH = this.stageHeight;

			// calculate a scale factor
			// this is needed for scaling a player
			this._service.setScaleFactor( this.rectWidth, this.rectHeight );

			// set a font size
			// so we can use it for EM
			this._renderer.setStyle( this.stage.nativeElement, 'font-size', this._service.settings.gridPosition.fontSize );

			// draw/update positions
			this.setGrid();    
			this.drawDots();
			this.updatePlayerPositions();

			// test
			// console.log("stage", this.w, this.h, "grid", this.gridX, this.gridY, "rectangle", this.rectWidth,this.rectHeight, "stage", this.stageWidth,this.stageHeight);

	    } else {
		  // same stage's width and height!!!
		  // don't do anything
		  return;
		}
    }

  	setGrid() {
  		// *Angular v10
		// check for mobile
		if (this._service.getDevice(this.w, this.h)[0] === true) {
		  // this is a mobile
		  // set width and height, edge to edge
		  // use margin to center a grid object
		  this._renderer.setStyle( this.gridObj.nativeElement, 'width', this.w + 'px' );
		  this._renderer.setStyle( this.gridObj.nativeElement, 'height', this.h + 'px');
		  this._renderer.setStyle( this.gridObj.nativeElement, 'margin-left', -this.w/2 + 'px' );
		  this._renderer.setStyle( this.gridObj.nativeElement, 'margin-top', -this.h/2 + 'px' );
		} else {
		  // this is desktop or tablet
		  // set width and height
		  // use margin to center a grid object
		  this._renderer.setStyle( this.gridObj.nativeElement, 'width', this.stageWidth + 'px' );
		  this._renderer.setStyle( this.gridObj.nativeElement, 'height', this.stageHeight + 'px');
		  this._renderer.setStyle( this.gridObj.nativeElement, 'margin-left', -this.stageWidth/2 + 'px' );
		  this._renderer.setStyle( this.gridObj.nativeElement, 'margin-top', -this.stageHeight/2 + 'px' );
		}
  	}

	drawDots() {
		// *Angular v10
		// for testing & development
		if(!this.stageHeight || !this.stageWidth) { return; }
		if(!this.rectHeight || !this.rectWidth) { return; }  

		// drawDots		
		if (this._service.settings.grids.drawGridDots ) {
		  // remove any previously attached dots
		  this.dotsArray.forEach(el => this._renderer.removeChild(this.dots.nativeElement,el) );
		  this.dotsArray = [];
		  // draw dots, one row at the time
		  let index = 0;
		  for (let y=this.rectHeight; y <= this.stageHeight; y=y+this.rectHeight) {
		    for(let x=this.rectWidth; x <= this.stageWidth; x=x + this.rectWidth) {  

		        this.dotsArray[index] = this._renderer.createElement('div');
		        this._renderer.setStyle( this.dotsArray[index], 'left', x + 'px' );
		        this._renderer.setStyle( this.dotsArray[index], 'top', y + 'px' );
		        this._renderer.addClass( this.dotsArray[index], 'dots' );
		        this._renderer.setAttribute(this.dotsArray[index], 'data-grid', (x/this.rectWidth)+'-'+(y/this.rectHeight));
		        this._renderer.appendChild( this.dots.nativeElement, this.dotsArray[index] );
		        index++;
		      // next horizontal dot
		    }
		    // next vertical row of dots
		  }
		}  
	}

	onResize() {
		// *Angular v10
		// window resize event
		this.hasRefreshed = true;
		this.calculate();    
	}

	updatePlayerPositions() {
		// *Angular v10
		// update coordinates on ALL cells within a stage
		this._service.eventRefreshPositions();
	}
}