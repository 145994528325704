import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AihelperService {

	constructor() { 
		console.log("LOAD",this.constructor.name);
	}


	checkAllCells(cells, support) {
		// return objects based on IDs
		let array = {};		
		let players = {}; // all cells, players and ai
		let empty = {}; // all empty cells
		let blobs = {}; // moving cells
		let ai = {}; // ai cells only
		let aiWar = {}; // ai cells in war
		let aiReady = {}; // ai cells not in war
		let human = {}; // players cells
		let humanWar = {}; // players cells in war
		let humanReady = {}; // players cells not in war
		let score = {}; // all couters
		score['ai'] = 0;
		score['player'] = 0;
		score['empty'] = 0;

		for(let t in support) {
			blobs[t] = {}
			blobs[t].id = support[t].id;
			blobs[t].whois = support[t].whois;
			blobs[t].targetID = support[t].targetID;
			blobs[t].fromID = support[t].fromID;
			blobs[t].num = support[t].num;

			// calculate a winning percentage
			score[blobs[t].whois] += support[t].num;
		}

		// all players
		for(let t in cells) {
			// go through all players
			players[t] = {};
			players[t].id = cells[t].id;
			players[t].whois = cells[t].whois;
			players[t].coordX = cells[t].coordX;
			players[t].coordY = cells[t].coordY;
			players[t].counter = cells[t].counter ? cells[t].counter : 0;
			players[t].enemyCounter = cells[t].enemyCounter ? cells[t].enemyCounter : 0;
			players[t].fighting = cells[t].fighting ? true : false;
			players[t].blobs = [];
			players[t].blobsFriendly = [];
			players[t].blobsEnemy = [];

			// calculate a winning percentage
			score[cells[t].whois] += players[t].counter 

			// go through moving blobs
			// and check if they are friendly or not
			for(let b in blobs) {
				if ( blobs[b].targetID === cells[t].id ) {
					players[t].blobs.push(blobs[b]);
					if( blobs[b].whois === cells[t].whois )	{
						players[t].blobsFriendly.push(blobs[b]);	
					} else {
						players[t].blobsEnemy.push(blobs[b]);
					}
				}
			}

			// fill in oter player related arrays
			if (cells[t].whois === 'player') {
				// human cell
				human[t] = players[t];
			} 
			if(cells[t].whois === 'player' && cells[t].fighting) {
				// human cell in war
				humanWar[t] = players[t];	
			} 
			if(cells[t].whois === 'player' && !cells[t].fighting) {
				// human cell not in war
				humanReady[t] = players[t];	
			}
			if (cells[t].whois === 'ai') {
				// ai cell
				ai[t] = players[t];
			} 
			if(cells[t].whois === 'ai' && cells[t].fighting) {
				// ai cell in war
				aiWar[t] = players[t];	
			}
			if(cells[t].whois === 'ai' && !cells[t].fighting) {
				// ai cell in war
				aiReady[t] = players[t];	
			}
			if (cells[t].whois === 'empty') {
				// empty cell
				empty[t] = players[t];
			} 
		}

		// return all sets
		array['players'] 	= players;
		array['blobs'] 		= blobs;
		array['ai'] 		= ai;
		array['aiWar'] 		= aiWar;
		array['aiReady'] 	= aiReady;
		array['human'] 		= human;
		array['humanWar'] 	= humanWar;
		array['humanReady'] = humanReady;
		array['empty'] 		= empty;
		array['score'] 		= score;

		return array;
	}


	sortKeyValue(array: any, key: string, sortby: string) {
		// *Angular v10
		// !!! important check if input is an object or array
		// OBJECT must be changed to ARRAY
		let sorted =  [];
		let check = Array.isArray(array) ? 'array' : 'object';
		if ( check === 'object' ) {
			// change object to array
			for (var p in array) { sorted.push(array[p]); }
		}
		// sort by key value as number or alpha
		if(sortby === 'DESC') {
			sorted =  sorted.sort((a, b) => b[key]-a[key]);	
		} else {
			sorted = sorted.sort((a, b) => a[key]-b[key]);
		}		
		// return as array
		return sorted;
	}


	filterKeyValue(array: any, key: string, value: number) {
		// *Angular v10
		// filter array or object
		let sorted =  [];
		// filter by key value
		for (var i in array) {
			if (array[i][key] < value) {
				sorted.push(array[i]);
			}
		}
		// return as array
		return sorted;
	}

	filterMinKeyValue(array: any, key: string, value: number) {
		// *Angular v10
		// filter array or object
		let sorted =  [];
		// filter by key value
		for (var i in array) {
			if (array[i][key] > value) {
				sorted.push(array[i]);
			}
		}
		// return as array
		return sorted;
	}



}
