import { Injectable } from '@angular/core';
import * as Tone from 'tone';
// https://www.soundotcom.com/genre/Epic/Adventure
// https://www.epidemicsound.com/sound-effects/search/?tags=fake%20flatulence&term=squeeze

@Injectable({ providedIn: 'root' })
export class SoundsService {

	// settings from a game
	private allowSoundFx:boolean;
	private allowMusic:boolean;
	private allowSoundsInGame:boolean;
	// tone js
	private seeker:number;
	private player:any;
	private fx:any;
	private soundFXarray = [];
	private soundFXpath: string;
	private musicArray = [];
	private musicIndex = -1;

	constructor() {
		// *Angular v10
		// default settings
		this.allowSoundsInGame = true; // always starts as TRUE
		this.soundFXpath = './assets/fx/';
		this.soundFXarray = ['click','buy','cancel','popup','war','move_player','move_ai','notallowed','win','lose'];
		this.musicArray[0] = './assets/music/music-acc0.mp3'; // homepage music
		this.musicArray[1] = './assets/music/music-acc1.mp3'; // stage 1
		this.musicArray[2] = './assets/music/music-acc2.mp3'; // stage 2
		this.musicArray[3] = './assets/music/music-acc3.mp3'; // stage 3
		this.musicArray[4] = './assets/music/music-acc4.mp3'; // stage 4
		this.musicArray[5] = './assets/music/music-acc5.mp3'; // stage 5

		// init a Tone
		this.seeker = 0;
		this.player = false;
		this.fx = false;

		// trace a service
		console.log("LOAD", this.constructor.name);
	}

	// *******************************
	// ****** global controls *******
	// *******************************
	playMusic() {
		// *Angular v10
		if (!this.allowMusic) { return; } // music is off in settings
		if (this.musicIndex == -1 ) { return; } // music index must have a music

		// CHECK IF INDEX is O.K.
		if (this.player.playingIndex == this.musicIndex) {
			// *********************************
			// SAME INDEX, SAME MUSIC	
			// *********************************		
			if ( this.player.state == 'stopped') {
				// music is stopped, make it play again
				// console.log("- [snd] playMusic, status stopped, resume");
				if (!this.allowSoundsInGame) { return; } // mute button is pressed
				// resume music
				this.player.start(); // start the music
				this.player.seek ( this.seeker ); // resume from offset
				return;
			}
			if ( this.player.state == 'started') {
				// same music, do nothing
				// console.log("- [snd] playMusic, status started");
				return;
			}
		} else {
			// *********************************
			// DIFFERENT INDEX, CHANGE THE MUSIC
			// *********************************
			if ( this.player.state == 'stopped') {				
				// console.log("- [snd] setMusic, status stopped");	
				if (this.allowSoundsInGame) { 
					// [ BUG & FIX ]
					// if you are switching swiftly between 2 musics
					// it stoppes here: fadeout is in progress, 
					// therefore set a new music as autoplay
					this.setMusic( true );
				} else {
					// mute button is on
					// music is stopped, change it without autoplay
					this.setMusic( false );	
				}				
				return;				
			}
			if ( this.player.state == 'started') {
				// music is playing, fade out first
				// than we change it to new index
				// console.log("- [snd] fadeOut, status started", this.player.playingIndex);
				this.player.onstop = () => {
					this.player.disconnect(); 				// <- do we need this?
					this.player.dispose(); 					// <- do we need this?
					this.player = {}; // delete the instance
					this.playMusic(); // change the music
				};			
				this.player.stop( "+0.1" ); // fadeout
				return;
			}
			// new index, change the music with autoplay
			// console.log("- [snd] setMusic, new music");
			this.setMusic( true );
			return;
		}

	}
	setMusic( autoplay: boolean ) {
		// *Angular v10
		// set new music, based on index
		console.log( "- [snd] set music index:", this.musicIndex, " & autoplay:", autoplay);
		this.player = new Tone.Player( this.musicArray[ this.musicIndex ], () => {
			this.player.loop  = true;
			this.player.fadeIn  = 0.3;
			this.player.fadeOut = 0.1;
			this.player.autostart = autoplay ? true : false;
			this.player.playingIndex = Number ( this.musicIndex );
		}).toDestination();
		this.seeker = 0; // reset seek	
	}
	pauseMusic() {
		// *Angular v10
		// pause the music, and remember seek time
		if ( this.player.state == 'started') {
			this.seeker = Number( this.player.now() ); // remember time			
			this.player.stop( "+0.1" ); // delay for fadeout effect
		}
	}

	toggleFX( playing: boolean ) {
		// *Angular v10
		// *******************************
		// ***** fx settings *************
		// *******************************
		//console.log("- [snd] toggle fx in app settings: " + playing);
		if( playing ) {
			this.allowSoundFx = true;
		} else {
			this.allowSoundFx = false;
		}			
	}

	toggleMusic( playing: boolean ) {
		// *Angular v10
		// *******************************
		// ****** music settings *********
		// *******************************
		//console.log("- [snd] toggle music in app settings: " + playing);
		if( playing ) {
			this.allowMusic = true;
			this.playMusic();
		} else {
			this.allowMusic = false;
			this.pauseMusic();
		}
	}

	toggleGameSounds( playing: boolean ) {
		// *Angular v10
		// *******************************
		// ******** mute button **********
		// *******************************
		if( playing ) {
			// console.log("- [snd] resume music - unmute button pressed");
			this.allowSoundsInGame = true;			
			if (this.allowMusic) { 
				// !!! resume in-game music !!!
				this.playMusic()	
			}			
		} else {
			// console.log("- [snd] pause music - mute button pressed");
			this.allowSoundsInGame = false;	
			if (this.allowMusic) { 
				// !!! pause in-game music !!!				
				this.pauseMusic()					
			}
		}
	}

	playHomeScreenMusic() {
		// *Angular v10
		// *******************************
		// ****** play home theme ********
		// *******************************
		this.musicIndex = 0;
		this.playMusic();
	}

	playStageMusic( stage: number ) {
		// *Angular v10
		// *******************************
		// ***** play in-game music ******
		// *******************************
		this.musicIndex = Number (stage);
		this.playMusic();
	}

	// we don't need this
	updateStageMusic( percent: number ) {
		// *******************************
		// **** update every second ******
		// *******************************
		// @percent : Number between 0 / 100;
		//    0     : player lost, ai won
		//  1 - 30  : loosing
		// 31 - 69  : neutral
		// 70 - 99  : winning
		//   100    : player won, ai lost
		// !!! DO YOUR MAGIC
		// console.log("- [snd] update music based on percent: "+ percent);
	}

	playSoundFX( sound: any ) {
		// *******************************
		// ******* play sound fx *********
		// *******************************
		if (!this.allowSoundFx) { return; }
		if (!this.allowSoundsInGame) { return; }
		// just in case	
		if (!this.soundFXarray.includes( sound )) { return; }
		// everything is ok, play a sound
		// !!! PLAY A SOUND FX
		//console.log("- [snd] playing sounf fx: "+ sound);
		this.fx = new Tone.Player( this.soundFXpath+sound+'.mp3', () => {
			this.fx.loop  = false;
			this.fx.autostart = true;
		}).toDestination();

	}


}
