import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SaveService {

  constructor() {
    console.log("LOAD", this.constructor.name);
   }






  // ********************************
  // ********************************
  // USE local-storage for time being
  // L O A D ************************
  // ********************************
  // ********************************
  loadData(key) {
    // *Angular v10
    return window.localStorage.getItem( key );
  }







  // ********************************
  // ********************************
  // USE local-storage for time being
  // S A V E ************************
  // ********************************
  // ********************************
  saveData(value,key,json=true) {
    // *Angular v10
    // save storage with a key
    if (!value) { return; }    
    // console.log(this.constructor.name, "+SAVE to localStorage:" , key, value);
    if (json) {
      // save it as JSON stringif
      window.localStorage.setItem(key,JSON.stringify(value));
    } else {
      // save it as a STRING
      // in case of error, we can save it as a string for further analyse
      window.localStorage.setItem(key,value);  
    }    
  }


  

}
