import { Component, NgZone, Input, ViewChild, ElementRef, ChangeDetectorRef, Renderer2 , OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlayerService } from './../services/player.service';
import { gsap, TextPlugin } from "gsap/all";
	gsap.registerPlugin(TextPlugin);


@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})

export class PopupComponent implements OnInit {

	// defaults
	private timeline;
	private animation;
	private toasting;
	private duration = 0.3;
	private opacity = 0.8;
	private move = -10;
	private delay = 0.25;
	private action;
	private visible;
	private activeRouter = null;
	private deviceDetection;

	public popupObject;
	public popupType;
	public popupTime;
	public popupStars;
	public popupTypesAllowed;
	public popupTitle;
	public popupName;
	public popupProduct;
	public toastType;
	private toastVisible;
	private initialX = null;

	// event listeners, use with care!
	private cardEventStart: () => void;
	private cardEventMove: () => void;

	@ViewChild('modalPopup') modalPopup: ElementRef;
	@ViewChild('modalOverlay') modalOverlay: ElementRef;
	@ViewChild('productPopup') productPopup: ElementRef;
	@ViewChild('authValue') authValue: ElementRef;
	@ViewChild('toastPopup') toastPopup: ElementRef;

	constructor(
		private _renderer: Renderer2,
		public _service: PlayerService,
		private _router: Router,
		private _refresh: ChangeDetectorRef,
		private _zone: NgZone
	) {}

	ngOnInit() {
		// *Angular v10
		// init everything
		// register a component to a global player.service scope
		this._service.popup = this;

		// init visibility position
		this.visible = false;
		this.toastVisible = false;
		
		// set timelines
		this.timeline = gsap.timeline();
		this.animation = gsap.timeline();
		this.toasting = gsap.timeline();

		// default type
		this.popupType = '';
		this.popupTypesAllowed = ['ready','go','lost','win','best','info','reset','product','buystars','usegoodies'];

		// some device detection
		this.deviceDetection = this._service.getDevice(100,100);

		console.log("%c INIT: " + this.constructor.name, "background: #eee; color: #333;");
	}

	openModalWindow(data){		
		// *Angular v10
	
		if ( this.visible ) { return; }	
		if ( !data ) { return; }
		if ( !data.type ) { return; }

		// check if popup has a html construction
		if ( !this.popupTypesAllowed.includes(data.type)) { return; }		

		this.popupType = data.type;
		this.popupObject = data;

		// make force refresh
		// window.location.href = this._service.getURLforPopup();
		this._refresh.detectChanges();

		console.log("%c OPEN popup   ", "background: #FAE1B0; color: #555; font-weight: bold", this.popupType);
		

		// set to inital states
		// gsap.set( '.rounded', {clearProps:'scale,rotate'});		
		gsap.set( this.modalOverlay.nativeElement, {opacity:0});
		gsap.set( this.modalPopup.nativeElement, {opacity:0, y: this.move, scale: 1});

		// add modal-open class to a body and remove hidden classes
		this._renderer.addClass(document.body, 'modal-open');
		this._renderer.removeClass(this.modalOverlay.nativeElement, 'hidden');
		this._renderer.removeClass(this.modalPopup.nativeElement, 'hidden');

		// set a visibility status 
		// so you are not able to open another popup
		this.visible = true;

		// check if there is any timeline
		// and pause a timeline
		this._service.popupOpened();

		// animate with gsap
		this.timeline.clear();
		this.timeline = gsap.timeline();
		this.timeline.to(this.modalOverlay.nativeElement, {opacity: this.opacity, duration: this.duration}, 0);	
		this.timeline.to(this.modalPopup.nativeElement, {opacity: 1, y: 0, duration: this.duration, ease: "back.out(2)" },this.delay);

		// custom animations
		if (this.popupType == 'ready') { 
			this.countdown(); 
		}

		// custom animations
		if (this.popupType == 'go') { 
			this.animateBlink(); 
		}

		// swipe detection for flipping products
		if (this.popupType === 'product') {
			// detect if mobile or tablet
			if (this.deviceDetection[1] || this.deviceDetection[2]) { this.addListenerflipCard(); } 
		}

		// sounds
		if(this.popupType === 'win') {
			this._service.soundFX ( 'win' );
		} else if(this.popupType === 'best') {
			this._service.soundFX ( 'win' );
		} else if(this.popupType === 'lost') {
			this._service.soundFX ( 'lose' );
		} else {
			this._service.soundFX ( 'popup' );
		}

	}

	closeModalWindow() { 
		// *Angular v10
		if (!this.visible) { return; }

		// remove event listenter
		if(this.cardEventStart) {
			this.cardEventStart();
			this.cardEventMove();
			this.cardEventStart = null;
			this.cardEventMove = null;
		}
		// clear timeline
		this.timeline.clear();
		this.timeline = gsap.timeline({
			onComplete:this.clearModalWindow, // run again
			callbackScope:this //make sure the scope stays in this
		});			
		this.timeline.to(this.modalOverlay.nativeElement, {opacity: 0, duration: this.duration/2},0);
		this.timeline.to(this.modalPopup.nativeElement, {opacity:0, y: this.move, duration: this.duration/2},0);
		this._renderer.removeClass(document.body, 'modal-open');

		// reset visibility status
		this.visible = false;
	}

	clearModalWindow() {
		// *Angular v10
		this._renderer.addClass(this.modalOverlay.nativeElement, 'hidden');
		this._renderer.addClass(this.modalPopup.nativeElement, 'hidden');

		// reset visibility status
		this.visible = false;

		// check if there is any timeline
		// and resume timeline
		this._service.popupClosed();

		// reset popupType & object
		this.popupType = '';
		this.popupObject = {};
	}

	gotoActiveRouter() {
		// *Angular v10
		this._router.navigate([this.activeRouter]);	
	}

	actionModalWindow(action, secondary=null) {
		// *Angular v10
		// type of actions
		// @action: 'close' - close a popup
		// @action: 'home' - go to home route
		// @action: 'acquire' - get some stuff
		// @action: 'purchase' - buy stars
		// @action: 'start' - start the game

		switch(action) {
			case 'close':
				this._service.soundFX ( 'cancel' );
				this.closeModalWindow();				
				break;
			case 'home':
				this._service.soundFX ( 'click' );
				this.closeModalWindow();
				// make sure it runs inside angular zone
				// if it is triggerd in 3rd party library it could throw an error
				this._zone.run(() => { this._router.navigate(['/levels']) });					
				break;
			case 'cart':
				this._service.soundFX ( 'click' );
				this.closeModalWindow();
				// make sure it runs inside angular zone
				// if it is triggerd in 3rd party library it could throw an error
				this._zone.run(() => { this._router.navigate(['/cart']) });					
				break;
			case 'acquire':				
				this.closeModalWindow();
				this._service.eventGoodiesAquired( this.popupObject.index );		
				break;
			case 'purchase':				
				this.closeModalWindow();
				this._service.eventPurchaseStar( this.popupObject.index );		
				break;
			case 'use':				
				this.closeModalWindow();
				this._service.eventGoodieUse( secondary );		
				break;
			case 'reset':
				this._service.soundFX ( 'click' );	
				this.closeModalWindow();
				this._service.resetTheProgress();		
				break;
			case 'start':
				this.closeModalWindow();		
				break;
			default :
				this.closeModalWindow();
		}
	}


	// **************************************
	// CUSTOM ANIMATIONS ********************
	countdown() {
		// *Angular v10
		let t1 = 0.5;
		let t2 = 0.5;
		let s1 = 1;
		let s2 = 1.1;
		let d1 = 1;

		this.animation.clear();
		this.animation = gsap.timeline({ callbackScope:this, onComplete: this.closeModalWindow });
		this.animation.set('#countdown', {text:'3', scale: 0.9, opacity: 0}, d1);
		this.animation.to('#countdown', {scale: s2, opacity: 1, duration: t1});
		this.animation.to('#countdown', {scale: s1, duration: t2});
		this.animation.set('#countdown', {text:'2', scale: s1});
		this.animation.to('#countdown', {scale: s2, duration: t1});
		this.animation.to('#countdown', {scale: s1, duration: t2});
		this.animation.set('#countdown', {text:'1', scale: s1});
		this.animation.to('#countdown', {scale: s2, duration: t1});
		this.animation.to('#countdown', {scale: s1, duration: t2});
	}

	// ******
	// BLINK
	animateBlink() {
		// *Angular v10
		let t1 = 0.5;
		this.animation.clear();
		this.animation = gsap.timeline({ repeat: -1, });
		this.animation.set('#iamready', {opacity: 1});
		this.animation.to('#iamready', {opacity: 0.5, duration: t1});
		this.animation.to('#iamready', {opacity: 1, duration: t1});
	
	}


	// **************
	// FLIPPING CARDS
	flipCard( swipeLeft = true) {
		// *Angular v10
		// determin rotation left/right
		let swipe =  swipeLeft? [90,180,270,360] : [-90,-180,-270,-360];
		// animation length
		let dur = 0.2;

		if( !this.productPopup.nativeElement.classList.contains( 'flipped') ) {
			// from front to back
			gsap.set(this.productPopup.nativeElement,{ rotateY: 0 });
			gsap.to (this.productPopup.nativeElement,{ rotateY: swipe[0], duration:dur, ease: "power2.in", callbackScope:this, onComplete: function() {
				this._renderer.addClass(this.productPopup.nativeElement, 'flipped');
				gsap.to(this.productPopup.nativeElement,{ rotateY: swipe[1], duration:dur, ease: "power2.Out" });
			}});
		} else {
			// from back to front
			gsap.set(this.productPopup.nativeElement,{ rotateY: swipe[1] });
			gsap.to (this.productPopup.nativeElement,{ rotateY: swipe[2], duration:dur, ease: "power2.in", callbackScope:this, onComplete: function() {
				this._renderer.removeClass(this.productPopup.nativeElement, 'flipped');
				gsap.to(this.productPopup.nativeElement,{ rotateY: swipe[3], duration:dur, ease: "power2.Out" });
			}});
		}
	}
	addListenerflipCard() {
		// *Angular v10
		// add angular renderer2 eventListener
		this.cardEventStart = this._renderer.listen(this.modalPopup.nativeElement, "touchstart", event => {
	    	this.initialX = event.touches[0].clientX;
	    });
	    this.cardEventMove = this._renderer.listen(this.modalPopup.nativeElement, "touchmove", event => {
			if (this.initialX === null) { return; }
			let currentX = event.touches[0].clientX; 
			let diffX = this.initialX - currentX;
		    if (diffX > 150) {
		      	// swiped left			      	
		      	this.initialX = null;
		 		this.flipCard(true);
		    } else if (diffX < -150) {
		      	// swiped right			      	
		      	this.initialX = null;
		 		this.flipCard(false);
		    }   
		  	event.preventDefault();	
	    });
	}



	// ***************
	// T O A S T *****
	openToast(type){
		// *Angular v10
		if(!type) { return; }
		// if(this.toastVisible) { return; }

		this.toastVisible = true;
		this.toastType = type;

		// make force refresh
		// window.location.href = this._service.getURLforPopup();
		this._refresh.detectChanges();

		// reset and set to init
		this.toasting.clear();
		this.toasting.kill();
		this.toasting = gsap.timeline({});
		this.toasting.set('.toast-box', { opacity:0 });
		this.toasting.set('.toast-box', { y:50 });
		this._renderer.removeClass(this.toastPopup.nativeElement, 'hidden');

		// wigle a bit
		this.toasting.to('.toast-box', { opacity:1, y:0, duration: 0.25 });
		this.toasting.to('.toast-box', { y:0, duration: 1.5, ease: "none" });
		this.toasting.to('.toast-box', { opacity:0, y:-50, duration: 0.25 , onComplete: this.closeToast, callbackScope:this });
	

	}
	closeToast() {
		// *Angular v10
		this._renderer.addClass(this.toastPopup.nativeElement, 'hidden');
		this.toastVisible = false

		// reset popupType
		this.toastType = '';		
	}

	enterAuth() {
		// *Angular v10
		// enter phrase
		this._service._storage.saveAuthorization( this.authValue.nativeElement.value );
		// hardcore reload
		location.reload();
	}

}
