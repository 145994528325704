import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IntroComponent } from './intro/intro.component';
import { LevelsComponent } from './levels/levels.component';
import { SettingsComponent } from './settings/settings.component';
import { CartComponent } from './cart/cart.component';
import { GameComponent } from './game/game.component';
import { PopupComponent } from './popup/popup.component';
import { CellComponent } from './game/cell/cell.component';
import { BlobComponent } from './game/cell/blob/blob.component';
import { HeaderComponent } from './header/header.component';
import { HoleComponent } from './game/cell/hole/hole.component';
import { FlipComponent } from './game/cell/flip/flip.component';
import { FieldComponent } from './game/cell/field/field.component';

import { PlayerService } from './services/player.service';
import { DataService } from './services/data.service';
import { SaveService } from './services/save.service';
import { StorageService } from './services/storage.service';
import { SoundsService } from './services/sounds.service';
import { AiService } from './services/ai.service';
import { AihelperService } from './services/aihelper.service';

import { SwiperModule } from 'swiper/angular';

@NgModule({
  declarations: [
    AppComponent,
    IntroComponent,
    LevelsComponent,
    SettingsComponent,
    CartComponent,
    GameComponent,
    PopupComponent,
    CellComponent,
    BlobComponent,
    HeaderComponent,
    HoleComponent,
    FlipComponent,
    FieldComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SwiperModule
  ],
  providers: [PlayerService,DataService,SaveService,StorageService,SoundsService,AiService,AihelperService],
  bootstrap: [AppComponent]
})
export class AppModule { }
