import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LevelsComponent } from './levels/levels.component';
import { GameComponent } from './game/game.component';
import { SettingsComponent } from './settings/settings.component';
import { CartComponent } from './cart/cart.component';
import { IntroComponent } from './intro/intro.component';

// redirect to a components
// blank should point to intro

const routes: Routes = [

		// intro
		{ path: 'intro', component: IntroComponent },

		// levels may have a slide (screen) ID
		{ path: 'levels', component: LevelsComponent },

		// game must have game level ID or json for testing
		{ path: 'game/:id', component: GameComponent },
		{ path: 'game', component: GameComponent },

		// settings
		{ path: 'settings', component: SettingsComponent },

		// cart
		{ path: 'cart', component: CartComponent },

		// blank should redirect to levels or intro
		{ path: '', redirectTo: 'intro', pathMatch: 'full' },
		{ path: '**', redirectTo: 'intro', pathMatch: 'full' }
];

@NgModule({
	imports: 	[RouterModule.forRoot(routes,{ scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' }) ], /* auto-scroll body ontop */ 
	exports: 	[RouterModule]
})

export class AppRoutingModule { }
