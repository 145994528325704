import { Injectable } from '@angular/core';
import { AihelperService } from './aihelper.service';

@Injectable({
  providedIn: 'root'
})

export class AiService {

	// defaults
	private cells;
	private ai;
	private target;
	private action;
	private attack;

	private foes;
	private empties;
	private aggresive;


	private tickers = 0;
	public _service;

	constructor(
		private _helper: AihelperService,
	) {
		console.log("LOAD",this.constructor.name);
	}

	updateAI() {
		// *Angular v10
		// AI.......is: BLUE
		// PLAYER...is: GREEN
		// EMPTY....is: GRAY

		// it returns all sets
		this.cells = this._helper.checkAllCells( this._service.getAllPlayer() , this._service.getMovings() );
		// @players = {}; // all cells, players and AI
		// @blobs = {}; // moving cells
		// @empty = {}; // empty cells
		// @ai = {}; // ai cells only
		// @aiWar = {}; // ai cells in war
		// @aiReady = {}; // ai cells not in war
		// @human = {}; // players cells
		// @humanWar = {}; // players cells in war
		// @humanReady = {}; // players cells not in war
		// @score = {}; // all counter combined for ai and player
		// console.log(this.cells);

		
		// SOUND ************************************
		// calculate a progress for a music variation
		let percent = Math.round( (this.cells.score.player * 100) / ( this.cells.score.ai + this.cells.score.player) );
		this._service._sounds.updateStageMusic( percent );
		

		// Called from player service every second
		// LEVELS
		switch (this._service.AILevel) {
			case 1:
				this.level_ONE();
				break;
			case 'level_ONE':
				this.level_ONE();
				break;
			case 2:
				this.level_TWO();
				break;
			case 'level_TWO':
				this.level_TWO();
				break;
			case 3:
				this.level_THREE();
				break;
			case 'level_THREE':
				this.level_THREE();
				break;
			case 4:
				this.level_TWO();
				break;
			case 'level_FOUR':
				this.level_TWO();
				break;
			case 5:
				this.level_TWO();
				break;
			case 'level_FIVE':
				this.level_TWO();
				break;

			default:
				this.level_ONE();
		}		
	}


	AI_attacks_TARGET(ai, target) {
		// *Angular v10
		// @ai:     BLUE,  CellComponent object
		// @target: GREEN, CellComponent object
		if (!ai) { return; }
		if (!target) { return; }

		try {
			// ai attacks player
			ai.moveToID(target.id);
			console.log("AI attacks from:", ai.id, "  to:" , target.id,ai.constructor.name,target.constructor.name);
		} catch (err) {}
	}


	level_ONE() {
		// *Angular v10
		// ********************************
		// AI LEVEL 1
		// go through easy targets
		// nothing special here, just check
		// who is the easiest target and go for it

		// *****************************************
		// ****** SIMPLE ATTACK ********************
		// attack empty
		this.action = this.attackEmpty();
		if ( this.action ) { return; }

		this.action = this.simpleAttack();
		if ( this.action ) { return; }
	
	}


	level_TWO() {
		// *Angular v10
		// ********************************
		// AI LEVEL 2
		// go through easy targets and check
		// who is the easiest target and go for it
		// make sure to defent
		// and try to attack with more then on cell	
		this.tickers++;	
		this.action = false;

		// attack empty
		this.action = this.attackEmpty();
		if ( this.action ) { return; }
		
		// simple attack
		this.action = this.simpleAttack();
		if ( this.action ) { return; }

		// simple defence
		this.action = this.simpleDefence();
		if ( this.action ) { return; }

		// only every 5 seconds
		// attack with 2 ai cells if possible
		if ( this.tickers > 5 ) { 			
			this.action = this.attactWithTwo();
			if ( this.action ) { this.tickers = 0; return; }
		}
	}


	level_THREE() {
		// *Angular v10
		// ********************************
		// AI LEVEL 3
		// go through easy targets and check
		// who is the easiest target and go for it
		// make sure to defent
		// and try to attack with more then on cell	
		this.tickers++;	
		this.action = false;

		// attack empty
		this.action = this.attackEmpty();
		if ( this.action ) { return; }

		// simple attack
		this.action = this.simpleAttack();
		if ( this.action ) { return; }

		// simple defence
		this.action = this.simpleDefence();
		if ( this.action ) { return; }

		// only every 5 seconds
		// attack with 2 ai cells if possible
		if ( this.tickers > 5 ) { 			
			this.action = this.attactWithTwo();
			if ( this.action ) { this.tickers = 0; return; }
		}

		// attack with 3 ai cells if possible
		if ( this.tickers > 5 ) { 			
			this.action = this.attactWithThree();
			if ( this.action ) { this.tickers = 0; return; }
		}

	}




	simpleAttack() {
		// *****************************************
		// ****** SIMPLE ATTACK ********************
		// find the lowest target 
		// and attack with highest ai cell
		try {
			this.ai = this._helper.sortKeyValue( this.cells['aiReady'], 'counter', 'DESC' );
			this.ai = this.ai[0];		
			if (!this.ai) { return false; }

			// split selected AI counter by 2 to check my trashold for the attack
			let min = Math.round(this.ai.counter/2) - 5;

			// L O G I C
			// go through FOES, green players			
			// get available targets
			let allfoes = this._helper.sortKeyValue( this.cells['humanReady'], 'counter', 'ASC' );
			let foes = this._helper.filterKeyValue( allfoes, 'counter', min );
			let empty = this._helper.sortKeyValue( this.cells['empty'], 'counter', 'DESC' );

			// make sure EMPTIES are the first ones in an array	
			// do we have a target?
			this.attack = [];
			this.attack = this.attack.concat( empty , foes );
			if (this.attack.length === 0) { return false; }

			// check all targets, one by one
			// and determin if I can attack
			for (let t in this.attack) {
				// my target
				let target = this.attack[t];
				// check if has any moving blobs
				let move = this.attack[t].blobsEnemy.length > 0;		
				if (!move) {
					// we have a target !!!			
					this.ai = this._service.getPlayer(this.ai.id);
					this.target = this._service.getPlayer(this.attack[t].id);
					this.AI_attacks_TARGET(this.ai, target);				
					return true;
				}
			}
			return false;
		} catch (er) { return false; }
	}


	attactWithTwo() {
		// **********************************
		// **** ATTACK WITH 2 CELLS *********
		// try to attack with 2 ai cells
		// but those cells must not be in war
		try { 
			this.ai =  this._helper.sortKeyValue( this.cells['aiReady'], 'counter', 'DESC' );
			if (this.ai[0] && this.ai[1]) {			

				// do we have any player's movement toward ai cells
				if ( this.ai[0].blobsEnemy.length > 0 || this.ai[1].blobsEnemy.length > 0 ) { return false; }

				// calculate my summo counter
				let min = Math.round(this.ai[0].counter/2) + Math.round(this.ai[1].counter/2) - 8;

				// get available targets
				let allfoes = this._helper.sortKeyValue( this.cells['humanReady'], 'counter', 'ASC' );
				let foes = this._helper.filterKeyValue( allfoes, 'counter', min );
				this.attack = [];
				this.attack = this.attack.concat( foes );
				if (this.attack.length === 0) { return false; }

				// check all targets, one by one
				// and determin if I can attack
				for (let t in this.attack) {
					// my target
					let target = this.attack[t];
					// check if has any moving blobs
					let move = this.attack[t].blobsEnemy.length > 0;			
					if (!move) {		
						// we have a target !!!	
						this.ai[0] = this._service.getPlayer(this.ai[0].id);
						this.ai[1] = this._service.getPlayer(this.ai[1].id);
						this.target = this._service.getPlayer(this.attack[t].id);		
						this.AI_attacks_TARGET(this.ai[0], target);
						this.AI_attacks_TARGET(this.ai[1], target);
						return true;
					}
				}
			}
			return false;
		} catch (er) { return false; }
	}

	attactWithThree() {
		// **********************************
		// **** ATTACK WITH 3 CELLS *********
		// try to attack with 3 ai cells
		// but those cells must not be in war
		try { 
			this.ai =  this._helper.sortKeyValue( this.cells['aiReady'], 'counter', 'DESC' );
			if (this.ai[0] && this.ai[1] && this.ai[2]) {			

				// do we have any player's movement toward ai cells
				if ( this.ai[0].blobsEnemy.length > 0 || this.ai[1].blobsEnemy.length > 0 || this.ai[2].blobsEnemy.length > 0) { return false; }

				// calculate my summo counter
				let min = Math.round(this.ai[0].counter/2) + Math.round(this.ai[1].counter/2) + Math.round(this.ai[2].counter/3)- 5;

				// get available targets
				let allfoes = this._helper.sortKeyValue( this.cells['humanReady'], 'counter', 'ASC' );
				let foes = this._helper.filterKeyValue( allfoes, 'counter', min );
				this.attack = [];
				this.attack = this.attack.concat( foes );
				if (this.attack.length === 0) { return false; }

				// check all targets, one by one
				// and determin if I can attack
				for (let t in this.attack) {
					// my target
					let target = this.attack[t];
					// check if has any moving blobs
					let move = this.attack[t].blobsEnemy.length > 0;			
					if (!move) {		
						// we have a target !!!	
						this.ai[0] = this._service.getPlayer(this.ai[0].id);
						this.ai[1] = this._service.getPlayer(this.ai[1].id);
						this.ai[2] = this._service.getPlayer(this.ai[2].id);
						this.target = this._service.getPlayer(this.attack[t].id);		
						this.AI_attacks_TARGET(this.ai[0], target);
						this.AI_attacks_TARGET(this.ai[1], target);
						this.AI_attacks_TARGET(this.ai[2], target);
						return true;
					}
				}
			}
			return false;
		} catch (er) { return false; }
	}

	simpleDefence() {
		// *****************************************
		// ****** SIMPLE DEFENCES ******************
		// find out which cell is being attacked
		// and try to defend it with nearest ai cell
		try { 
			let defence = {};
			let attacked = this._helper.sortKeyValue( this.cells['aiWar'], 'counter', 'DESC' );
			let defender = this._helper.sortKeyValue( this.cells['aiReady'], 'counter', 'DESC' );

			// make sure we have anyone to defend
			if (attacked.length > 0 && defender.length > 0) {
				for (let t in attacked) {
					// am I loosing?
					defence['id1'] = null;
					defence['id2'] = null;
					defence['dx'] = 99999999;
					if( attacked[t].counter < attacked[t].enemyCounter) {
						let x1 = attacked[t].coordX;
						let y1 = attacked[t].coordY;
						let id1 = attacked[t].id;
						// console.log("loosing", attacked[t].id, attacked[t].counter, attacked[t].coordX, attacked[t].coordY);
						for (let i in defender) {
							// find nearest point
							let x2 = defender[i].coordX;
							let y2 = defender[i].coordY;
							let id2 = defender[i].id;
							let dx = (x2-x1)**2 + (y2-y1)**2; // pytagoras, dont bother with square root
							// update defence dx
							if ( dx < defence['dx'] && attacked[t].blobsFriendly.length === 0 ) {
								defence['id1'] = id1;
								defence['id2'] = id2;
								defence['dx'] = dx;	
							}							
						}
					}
				}
				// defend ai cell
				if ( defence['id1'] && defence['id2']) {
					this.AI_attacks_TARGET( this._service.getPlayer(defence['id2']), this._service.getPlayer(defence['id1']) );
					return true;
				}
			}
			return false;
		} catch (er) { return false; }
	}

	attackEmpty() {
		// *****************************************
		// ****** ATTACK ON EMPTY ******************
		// more advanced attack
		// attack with nearest ai cell
		let empty = this._helper.sortKeyValue( this.cells['empty'], 'counter', 'DESC' ); 
		this.ai = this._helper.sortKeyValue( this.cells['aiReady'], 'counter', 'DESC' );

		// do we have any empty
		if (empty.length === 0) { return false; }
		for (let i in empty) {			
			// info from empty
			let defence = {};
			defence['id1'] = null;
			defence['id2'] = empty[i].id;
			defence['dx'] = 99999999;
			for (let t in this.ai) {
				let x1 = empty[i].coordX;
				let y1 = empty[i].coordY;
				let x2 = this.ai[t].coordX;
				let y2 = this.ai[t].coordY;
				let dx = (x2-x1)**2 + (y2-y1)**2; // pytagoras, dont bother with square root
				// check for ai
				let blobs = false;
				for (let b in empty[i].blobsEnemy) {
					if ( empty[i].blobsEnemy[b].whois === 'ai') { blobs=true; }	
				}
				if ( dx < defence['dx'] && !blobs ) {
					defence['dx'] = dx;	
					defence['id1'] = this.ai[t].id;
				}
			}
			if ( defence['id1'] && defence['id2']) {

				this.ai = this._service.getPlayer( defence['id1'] );
				this.target = this._service.getPlayer( defence['id2'] );
				this.AI_attacks_TARGET(this.ai, this.target);
				return true;
			}
		}
		return false;
	}

	advancedAttack() {
		// *****************************************
		// ****** ADVANCED ATTACK ******************
		// find the lowest players target 
		// and attack with nerest ai cell

		let human = this.cells['humanReady'];
		let min = 0;
		for (var t in human) {
			min = 4;
			min = min + human[t].counter
			human[t].blobsFriendly.map( el => min = min + Math.round(el.num) );
			human[t].blobsEnemy.map( el => min = min - Math.round(el.num) );
			human[t].min = min;
			// check if there is any ai to attack from
			let allfoes = this._helper.sortKeyValue( this.cells['aiReady'], 'counter', 'DESC' );
			human[t].attack = this._helper.filterMinKeyValue( allfoes, 'counter' , min*2 );
		}	
		// sort by smallest counter
		human = this._helper.sortKeyValue( human, 'min', 'ASC' ); 
		console.log( human );
		
		/*
		// summ all player blobs and substract ai blobs
		let min = 0;
		min = min + Math.round( human[0].counter );
		human[0].blobsFriendly.map( el => min = min + Math.round(el.num) );
		human[0].blobsEnemy.map( el => min = min - Math.round(el.num) );
		min = min * 2;

		this.ai = this._helper.filterMinKeyValue( this.cells['aiReady'], 'counter' , min)
		// can we attack anything
		if (human.length === 0 || this.ai.length === 0 ) { return false; }


		console.log(min, human[0].id )

		// find the nerest target
		let defence = {};
		defence['id1'] = null;
		defence['id2'] = human[0].id;
		defence['dx'] = 99999999;
		for (let t in this.ai) {
			let x1 = human[0].coordX;
			let y1 = human[0].coordY;
			let x2 = this.ai[t].coordX;
			let y2 = this.ai[t].coordY;
			let dx = (x2-x1)**2 + (y2-y1)**2;
		}
		*/


		


/*
let min = Math.round(this.ai[0].counter/2) + Math.round(this.ai[1].counter/2) - 8;

				// get available targets
				let allfoes = this._helper.sortKeyValue( this.cells['humanReady'], 'counter', 'ASC' );
				let foes = this._helper.filterKeyValue( allfoes, 'counter', min );

*/

		return false;
	}


	
}
