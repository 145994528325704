<!-- header -->
<app-header [_type]="'mainHeader'"></app-header>

<div #mainScreen class="levels">
<swiper	 
	effect="coverflow" 
	[slidesPerView]="1" 
	[initialSlide] = "this.slideID"
	(swiper)="onSwiper($event)" 
	(slideChange)="onSlideChange()" 
	[spaceBetween]="100"
	[autoHeight]="true" 
	[pagination]="{ clickable: true }">
<ng-container *ngFor="let page of this.stages; index as indexstage">
	<ng-template swiperSlide>
			<div class="swiper-slide">
				<h1>{{page.name}}</h1>
		    	<p class="small">{{page.comment}}</p>
		    	<div #mainGrid class="grid">
		    		<!-- get levels within a scene, start/end condition -->
		    		<ng-container *ngFor="let card of levels; index as indexcount">
		    			<figure 
		    				*ngIf="(indexcount >= page.start) && (indexcount <= page.end)" 
		    				(click)="clickedLevel( indexcount, page.allow )" 
		    				[ngClass]="card.status" 
		    				class="button border-lite" 
		    				id="level{{indexcount}}">
		    				<!-- draw stars from progress -->
		    				<p *ngIf="card.stars==1" class="noselectX stars star{{card.stars}}">
								<i class="icon-star cards"></i>
								<i class="icon-star-outline cards"></i>
								<i class="icon-star-outline cards"></i>
							</p>
							<p *ngIf="card.stars==2" class="noselectX stars star{{card.stars}}">
								<i class="icon-star cards"></i>
								<i class="icon-star cards"></i>
								<i class="icon-star-outline cards"></i>
							</p>
							<p *ngIf="card.stars==3" class="noselectX stars star{{card.stars}}">
								<i class="icon-star cards"></i>
								<i class="icon-star cards"></i>
								<i class="icon-star cards"></i>
							</p>
							<p *ngIf="!card.stars" class="noselectX stars star{{card.stars}}">
								<i class="icon-star-outline cards"></i>
								<i class="icon-star-outline cards"></i>
								<i class="icon-star-outline cards"></i>
							</p>

		    				<h2 class="noselect drop">{{indexcount}}</h2>
		    				<p *ngIf="card.finished" class="noselectX status">Time: <strong>{{card.finished}}s</strong></p>
					  		<p *ngIf="!card.finished" class="noselectX status"> &nbsp; </p>
		    			</figure>
		    		</ng-container>

		    		<figure 
		    				*ngIf="page.completed==true" 
		    				class="button gold border-dark" 
		    				(click)="nextSlide()">   				
		    				<h4 class="noselectX">Achievement unlocked</h4>
		    				<h2 class="noselectX drop">{{page.extrastars}}<i class="icon-star-text inline"></i></h2>
		    				<h4>Good job!</h4>
		    		</figure>

		    		<figure 
		    				*ngIf="page.completed==false" 
		    				class="border-dark dimmed noselectX">   				
		    				<h4 class="noselectX">Achievement locked</h4>
		    				<h2 class="noselectX drop">?</h2>
		    				<h4>Finish all</h4>
		    		</figure>

		    	</div>	
			</div>
	</ng-template>
</ng-container>
</swiper>
</div>

